<template>
  <div class="modal fade show" style="display: block">
    <div class="loading">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
<style scoped>
  .loading {
    z-index: 3000;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffffa1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>