<template>
  <div>
    <div class="body-content">
      <small style="margin-bottom: 5px; display: block" v-for="error in errors['base']" :key="error" class="error">{{
        error }}</small>
      <div class="row">
        <div class="col-md-12">
          <label>お客様番号</label>
          <input v-model="number" type="text" class="form-control" />
          <small v-for="error in errors['number']" :key="error" class="error">{{
            error
          }}</small>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-6">
          <label>お名前(姓)<span class="require">必須</span></label>
          <input v-model="last_name" type="text" class="form-control" />
          <small v-for="error in errors['last_name']" :key="error" class="error">{{ error }}</small>
        </div>
        <div class="col-md-6">
          <label>お名前(名)</label>
          <input v-model="first_name" type="text" class="form-control" />
          <small v-for="error in errors['first_name']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-6">
          <label>お名前(セイ)</label>
          <input v-model="lastNameKana" type="text" class="form-control" />
          <small v-for="error in errors['last_name_kana']" :key="error" class="error">{{ error }}</small>
        </div>
        <div class="col-md-6">
          <label>お名前(メイ)</label>
          <input v-model="firstNameKana" type="text" class="form-control" />
          <small v-for="error in errors['first_name_kana']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-12">
          <label>電話番号</label>
          <input v-model="tel" type="tel" class="form-control" />
          <small v-for="error in errors['tel']" :key="error" class="error">{{
            error
          }}</small>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-12">
          <label>メールアドレス</label>
          <input v-model="email" type="text" class="form-control" />
          <small v-for="error in errors['email']" :key="error" class="error">{{
            error
          }}</small>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-12">
          <label>アレルギー</label>
          <div class="form-group">
            <textarea v-model="allergy" rows="3" class="form-control allergy-text-area"></textarea>
            <small v-for="error in errors['allergy']" :key="error" class="error">{{
              error
            }}</small>
          </div>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-12">
          <label>誕生日</label>
          <div class="form-group">
            <DatePicker input-class="form-control" v-model="birthday" value-type="format" format="YYYY/MM/DD"
              placeholder="例：1990/01/01" :clearable="false">
              <i slot="icon-calendar"></i>
            </DatePicker>
          </div>
          <small v-for="error in errors['birthday']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-12">
          <div style="display: flex">
            <label>お客様属性 </label>
            <span v-if="properties.filter((property) => property.shop_id != null)
              .length < 10
              " id="span-icon-customer" class="span-icon" @click="showModalProperty = true"
              style="cursor: pointer; margin-right: 20px">
              <i class="fa fa-duotone fa-plus icon-primary icon_allergy_add"></i>
            </span>
          </div>
          <div class="form-group">
            <div class="dp-flex">
              <div v-for="property in properties" :key="property.id" class="lb-checkbox">
                <label class="mr-10 dp-flex item_center_flex cursor-pointer">
                  <input type="checkbox" class="transform-checkbox" v-model="propertyIds" :value="property.id"
                    style="cursor: pointer" />
                  <span class="ml-10 mr-10" style="max-width: 90%">{{
                    property.name
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt_small">
        <div class="col-md-12">
          <label>備考</label>
          <textarea v-model="remark" class="form-control"></textarea>
          <small v-for="error in errors['remark']" :key="error" class="error">{{
            error
          }}</small>
        </div>
      </div>
    </div>

    <div v-if="!isCreateNew" class="table_customer_modal">
      <table class="customer_table">
        <thead>
          <th>来店日</th>
          <th>予約種別</th>
          <th>ステータス</th>
        </thead>
        <tbody>
          <tr v-for="(reservation, index) in reservations" :key="index">
            <td>{{ convertTime(reservation.start_time) }}</td>
            <td v-if="reservation.course_id == null">席のみ</td>
            <td v-else>コース</td>
            <td v-if="reservation.state == 'reserved'">予約</td>
            <td v-if="reservation.state == 'cancelled' ||
              reservation.state == 'restored'
              ">
              キャンセル
            </td>
            <td v-if="reservation.state == 'seating'">着席</td>
            <td v-if="reservation.state == 'finished'">会計済</td>
            <td v-if="reservation.state == 'not_show'">No Show</td>
          </tr>
        </tbody>
      </table>
      <div v-if="reservations.length == 0" class="reservation_null">
        表示するデータがありません。
      </div>
      <div class="customer-details">
        <div class="d-flex">
          <div class="d-flex-content d-block-content column-1">
            <span class="label">予約</span>
            <div class="count-reservation">
              {{ count_all_reservation }}回
            </div>
          </div>
          <div class="d-flex-content d-block-content column-2">
            <div class="label w-80">来店回数</div>
            <div v-if="is_edit_count_reservations" class="count-reservation">
              <input type="number" v-model="count_reservation" class="input-edit-count" min="0" oninput="validity.valid||(value='');">
            </div>
            <div v-else class="count-reservation">{{ count_reservation }}回</div>
          </div>
          <div class="d-flex-content d-block-content column-3">
            <div class="label w-80">キャンセル</div>
            <div v-if="is_edit_count_reservations" class="count-reservation">
              <input type="number" v-model="count_cancel" class="input-edit-count" min="0" oninput="validity.valid||(value='');">
            </div>
            <div v-else class="count-reservation">{{ count_cancel }}回</div>
          </div>
          <div class="d-flex-content d-block-content column-4">
            <div class="label w-80">No Show</div>
            <div v-if="is_edit_count_reservations" class="count-reservation">
              <input type="number" v-model="count_not_show" class="input-edit-count" min="0" oninput="validity.valid||(value='');">
            </div>
            <div v-else class="count-reservation">{{ count_not_show }}回</div>
          </div>
          <div>
            <button @click="is_edit_count_reservations = !is_edit_count_reservations" class="btn btn-primary btn-unfocus btn-edit-count">編集</button>
          </div>
        </div>
      </div>
    </div>
    <PropertyModal v-if="showModalProperty" @close-modal-allergy="showModalProperty = false" @loading="loading = $event"
      :get-properties="getProperties" />
  </div>
</template>

<script>
import PropertyModal from "../customer/components/PropertyModal.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import Http from "../../shared/http";
import moment from 'moment';

export default {
  components: {
    DatePicker,
    PropertyModal
  },
  data() {
    return {
      allergy: [],
      properties: [],
      errors: {},
      number: "",
      last_name: "",
      first_name: "",
      lastNameKana: "",
      firstNameKana: "",
      tel: "",
      email: "",
      remark: "",
      errors: {},
      propertyIds: [],
      birthday: null,
      nameProperty: null,
      showModalProperty: false,
      count_reservation: 0,
      count_cancel: 0,
      count_not_show: 0,
      is_edit_count_reservations: false,
    };
  },
  computed: {
    count_all_reservation() {
      return (parseInt(this.count_reservation) || 0) + (parseInt(this.count_cancel) || 0) + (parseInt(this.count_not_show) || 0);
    },
  },
  props: {
    reservations: {
      type: Array,
    },
    customer: {
      type: Object,
    },
    isCreateNew: {
      type: Boolean,
    },
  },
  watch: {
    customer: {
      handler(r) {
        this.number = r.number;
        this.last_name = r.last_name;
        this.first_name = r.first_name;
        this.firstNameKana = r.first_name_kana;
        this.lastNameKana = r.last_name_kana;
        this.tel = r.tel;
        this.email = r.email;
        this.remark = r.remark;
        this.birthday = r.birthday
          ? moment(r.birthday).format("YYYY/MM/DD")
          : null;
        this.allergy = r.allergy;
        this.propertyIds = r.property_ids != null ? r.property_ids : [];
        this.count_reservation = r.count_reservation;
        this.count_cancel = r.count_cancel;
        this.count_not_show = r.count_not_show;
        this.is_edit_count_reservations = false;
      },
    },
    number(newValue) {
      if (newValue != this.customer.number) {
        this.$emit('changeData');
      }
    },

    first_name(newValue) {
      if (newValue != this.customer.first_name) {
        this.$emit('changeData');
      }
    },

    last_name(newValue) {
      if (newValue != this.customer.last_name) {
        this.$emit('changeData');
      }
    },

    firstNameKana(newValue) {
      if (newValue != this.customer.first_name_kana) {
        this.$emit('changeData');
      }
    },

    lastNameKana(newValue) {
      if (newValue != this.customer.last_name_kana) {
        this.$emit('changeData');
      }
    },

    tel(newValue) {
      if (newValue != this.customer.tel) {
        this.$emit('changeData');
      }
    },

    email(newValue) {
      if (newValue != this.customer.email) {
        this.$emit('changeData');
      }
    },

    remark(newValue) {
      if (newValue != this.customer.remark) {
        this.$emit('changeData');
      }
    },

    birthday(newValue) {
      if (
        newValue !=
        (this.customer.birthday
          ? moment(this.customer.birthday).format("YYYY/MM/DD")
          : null)
      ) {
        this.$emit('changeData');
      }
    },

    propertyIds(newValue) {
      if (
        newValue.length !=
        (this.customer.property_ids != null ? this.customer.property_ids : [])
          .length
      ) {
        this.$emit('changeData');
      }
    },
  },
  async mounted() {
    await this.getProperties();
  },
  methods: {
    async getProperties() {
      this.loading = true;
      const slug =
        document.getElementById("slug").value == ""
          ? ""
          : `/${document.getElementById("slug").value}`;
      const path = `${slug}/shop_manager/properties/properties_manager`;
      await Http.get(path, {})
        .then((response) => {
          this.properties = response.data.properties;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    async onSave() {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = this.isCreateNew ? `${slug}/shop_manager/customer/add` : `${slug}/shop_manager/customer/${this.customer.id}`;
      const params = { customer: {
        number: this.number,
        last_name: this.last_name,
        first_name: this.first_name,
        last_name_kana: this.lastNameKana,
        first_name_kana: this.firstNameKana,
        tel: this.tel,
        email: this.email,
        remark: this.remark,
        allergy: this.allergy,
        property_ids: this.propertyIds,
        birthday: this.birthday,
        count_reservation: this.count_reservation,
        count_cancel: this.count_cancel,
        count_not_show: this.count_not_show,
      }};

      if (this.isCreateNew) {
        await Http.post(path, params)
        .then((response) => {
           this.$emit('submitSuccess');
        })
        .catch((error) => {
          this.$emit('loading', false)
          document.getElementById('modal-body').scrollTo(0, 0);
          this.errors = error.response.data;
        });
      }
      else {
        await Http.patch(path, params)
        .then((response) => {
          this.$emit('submitSuccess');
        })
        .catch((error) => {
          this.$emit('loading', false)
          document.getElementById('modal-body').scrollTo(0, 0);
          this.errors = error.response.data
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  height: 40px;
}

.modal {
  background-color: #2125298a;
}

.customer-details {
  padding-top: 10px;
  font-size: 16px;
  display: flex;

  .label {
    font-weight: 600;
  }

  .count-reservation {
    margin-left: 10px;
  }
}

button:focus {
  outline: none;
}

.btn-fill:focus {
  outline: none !important;
}

.customer_table {
  width: 100%;
  font-size: 15px;

  th {
    width: 33%;
    background-color: #0090d7;
    color: #fff;
    font-weight: unset;
    padding: 10px 0px 10px 60px;
  }

  td {
    padding: 5px 0px 5px 60px;
    border-bottom: 1px solid #DDDDDD;
  }
}

.dp-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.mr-10 {
  margin-right: 10px;
}

.disable {
  background: #E3E3E3;
  cursor: not-allowed;
  pointer-events: none;
}

.ml-10 {
  margin-left: 10px;
}

.allergy-remark {
  width: 50%;
}

.span-icon {
  width: 25px;
  height: 25px;
  border: 2px solid #0090d7;
  padding-left: 2.5px;
  padding-top: 0.5px;
  margin-top: 0 !important;
  color: #0090d7;
  margin-left: auto;
}

.icon_allergy_add {
  font-size: 20px;
}

.reservation_null {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.item_center_flex {
  align-items: center;
}

.customer-details {
  background: #fff1e8;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.body-content {
  padding: 1rem !important;
}

.transform-checkbox {
  width: 20px;
  height: 20px;
  accent-color: #0090d7;
}

.d-flex-content {
  display: flex;
}

.column-1 {
  padding-left: 20px;
  width: 115px;
}

.column-2 {
  width: 140px;
  margin-left: 10px;
}

.column-3 {
  width: 140px;
  margin-left: 10px;
}

.column-4 {
  margin-left: 10px;
  width: 140px;
}

#span-icon-customer {
  position: unset !important;
}

@media screen and (max-width: 480px) {
  .count-reservation {
    margin-left: 0 !important;
  }

  .d-block-content {
    display: block;
  }

  .column-1 {
    padding-left: 30px;
    width: 95px;
  }

  .column-2 {
    width: 85px;
  }

  .column-3 {
    width: 85px;
    margin-left: 20px;
  }

  .column-4 {
    margin-left: 20px;
    width: 80px;
  }

  .customer_table {
    th {
      width: 33%;
      background-color: #0090d7;
      color: #fff;
      font-weight: unset;
      padding: 10px 0;
      text-align: center;
    }

    td {
      padding: 5px 0;
      text-align: center;
      border-bottom: 1px solid #DDDDDD;
    }
  }
}
.btn-edit-count {
  margin: 0;
  padding: 4px 8px;
  font-size: 12px;
}
.allergy-text-area {
  line-height: 1.3;
}
.input-edit-count {
  width: 50px;
  height: 25px;
}
</style>
