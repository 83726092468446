<template>
  <div class="timetable mt-2" :style="`--colW: ${colW}; --cellW: ${colW/4}; --headerH: ${headerH}`">

    <div class="label-wrapper">
      <!-- table header -->
      <div class="table-header" :style="{ height: headerH, lineHeight: headerH+'px', top: headerTop }">
        <div class="shop-table-name text-while">テーブル名</div>
        <div class="capacity">人数</div>
      </div>
      <div :style="{ height: headerH}"></div>
      <!-- table list -->
      <div v-for="table, i in tables" :key="i" id="shop-table-name" class="table-row" :style="{ height: rowH + rowH*(table.overlapLevel || 0) }">
        <div class="shop-table-name" style="cursor: pointer;" @click="openReservationBlockModal(table)">
          {{table.name}}
        </div>
        <div class="capacity">
          {{table.number_of_people_min}} - {{table.number_of_people_max}}
        </div>
      </div>
      <div class="scrollbar-space"></div>
    </div>

    <div class="data-wrapper" ref="dataWrapper" id="data-wrapper">
      <div class="scroll-wrapper">
        <!-- hour header -->
        <div class="table-header" :style="{ height: headerH, lineHeight: headerH+'px', top: headerTop }">
          <div v-for="hour, i in workHours" :key="i" class="hour">{{hour%24}}:00</div>
        </div>
        <div :style="{ height: headerH}"></div>

        <!-- hour cells -->
        <div v-for="table, iTable in tables" :key="iTable" class="table-row" :style="{ height: rowH + rowH*(table.overlapLevel || 0) }">
          <template v-for="hour, i in workHours">
            <div @click="newReservation(table, hour, 0)"  class="cell" :key="i+0.0"></div>
            <div @click="newReservation(table, hour, 15)"  class="cell" :key="i+0.25"></div>
            <div @click="newReservation(table, hour, 30)"  class="cell" :key="i+0.50"></div>
            <div @click="newReservation(table, hour, 45)"  class="cell end-hour" :key="i+0.75"></div>
          </template>
        </div>
        <div class="current-time-line" :style="{ left: nowX }"></div>

        <!-- reservations -->
        <template v-for="table, i in tables">
          <template v-for="r in timeTable[i].reservations">
            <div class="reservation opacity-unset"
              v-show="!r.hide && getWidth(r.start, r.stay_minutes) > 0"
              :class="[ r.overlap && r.state != 'busy' ? 'overlap' : '', r.state ]"
              :key="`${i}.${r.id}`"
              :style="{
                height: rowH - 2 + rowH*(r.expandLevel || 0),
                top: headerH + rowH*table.offsetY + rowH*(r.overlapLevel || 0) + 1 - rowH*(r.expandLevel || 0),
                width: getWidth(r.start, r.stay_minutes),
                left: getX(r.start),
              }"
              v-popup="[r.memo,r.memo_from_customer]"
              :data-id="r.id"
              @click="r.state == 'busy' ? newReservation(table, null, null, is_block = true) : openReservation(r)"
            >
              <template v-if="r.state != 'busy'">
                <template v-if="r.customer.reservation_time_no">
                  <div class="square-number" v-if="r.customer.reservation_time_no == 1">初</div>
                  <div class="square-number" v-else-if="r.customer.reservation_time_no <= 9">{{r.customer.reservation_time_no}}</div>
                  <div class="square-number" v-else>多</div>
                </template>
                <div v-if="r.state == 'reserved'" class="table-merge-icon">
                  <img v-if="r.is_table_merged" style="padding-right: 5px;" src="../../../../../assets/images/icon_timetable/icon_link.svg" alt="img">
                  <img class="my-bell" v-if="hasNotice(r.id)" src="../../../../../assets/images/icon_timetable/bell.svg" alt="img">
                  <img class="my-fork" v-if="r.course_id!=null"  src="../../../../../assets/images/icon_timetable/icon_fork.svg" alt="img">
                  <img v-if="r.memo_from_customer || r.memo" src="../../../../../assets/images/icon_timetable/icon_message.svg" alt="img">
                </div>
                <div v-else class="table-merge-icon">
                  <img v-if="r.is_table_merged" style="padding-right: 5px;" src="../../../../../assets/images/icon_timetable/icon_link_while.svg" alt="img">
                  <img class="my-bell" v-if="hasNotice(r.id)" src="../../../../../assets/images/icon_timetable/bell.svg" alt="img">
                  <img class="my-fork" v-if="r.course_id!=null"  src="../../../../../assets/images/icon_timetable/icon_fork.svg" alt="img">
                  <img v-if="r.memo_from_customer || r.memo" src="../../../../../assets/images/icon_timetable/icon_message.svg" alt="img">
                </div>
                <template v-if="r.stay_minutes >= 30">
                  <div class="full-name"
                    :class="{ 'merge-table': r.customer.reservation_time_no }"
                  >
                    {{ r.type === 'pos' || r.type === 'walkin' ? '無' : `${r.last_name} ${r.first_name} 様` }}
                  </div>
                  <div class="number-of-people">{{r.number_of_people}}名</div>
                  <div class="type">{{r.type_label}}</div>
                </template>
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>

    <ReservationModal
      v-show="showReservationModal"
      :reservation="editReservation"
      :reservations="reservations"
      :customerEdit="customerEdit"
      @close="showReservationModal = false"
      :showResverveModal="showReservationModal"
      @loading="updateLoading"
      @reload="$emit('reload')"
      @openMergeModal="openMergeModal"
    >
    </ReservationModal>

    <MergeModal
      v-show="showMergeModal"
      :reservation="mergeReservation"
      @close="showMergeModal = false"
      @loading="updateLoading"
      @reload="$emit('reload')"
    >
    </MergeModal>

    <Loading v-show="loading" />

    <ReservationBlockModal :reservation="editReservation"
                           @reload="$emit('reload')"
                           :datetime_blocks="datetime_blocks"
                           :working-time="workingTime"
                           v-show="showModalReservationBlock"
                           @close="showModalReservationBlock = false" />
  </div>
</template>

<script>
import moment from 'moment';
import ReservationModal from '../../components/ReservationModal.vue';
import MergeModal from "./MergeModal.vue";
import Http from "../../../shared/http"
import Loading from '../../components/Loading';
import {DisableScrollBody} from "../../../shared/util";
import ReservationBlockModal from '../../components/ReservationBlockModal.vue';
import { isTouchable } from '../../../src/common/util';

export default {
  components: {ReservationModal, MergeModal, Loading, ReservationBlockModal},
  props: {
    date: {
      type: Date,
    },
    workingTimes: {
      type: Array,
      default: () => [],
    },
    timeTable: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      reservations: [],
      headerH: 40,
      rowH: 50,
      colW: 120,
      nowX: -10,
      showReservationModal: false,
      editReservation: {},
      showMergeModal: false,
      mergeReservation: {},
      headerTop: 0,
      customerEdit: {},
      showModalReservationBlock: false,
      reservationBlocks: [],
      datetime_blocks: [],
      workingTime: {},
      notices: [],
    };
  },
  computed: {
    workHours() {
      if (this.workingTimes.length < 1) return []
      let hours = Math.round(moment.duration(this.timeTableEnd.diff(this.timeTableStart)).asHours())
      const startHour = this.timeTableStart.hour()
      return new Array(hours).fill(0).map((_, i) => startHour + i)
    },
    timeTableStart() {
      const workStart = this.workingTimes[0].start_time
      return moment(workStart).set({minute: 0, second: 0, millisecond: 0})
    },
    timeTableEnd() {
      const workEnd = this.workingTimes[this.workingTimes.length - 1].end_time
      const end = moment(workEnd).set({minute: 0, second: 0, millisecond: 0})
      if (moment(workEnd).minute() > 0) end.add(1, 'hour')
      return end
    },
    tables() {
      return this.timeTable.map(t => t.table)
    },
  },
  created () {
    this.timerNowX = setInterval(this.updateNowX, 1000 * 60 * 5)
    this.timerAutoReload = setInterval(this.autoReload, 1000 * 60 * 15)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    clearInterval(this.timerNowX);
    clearInterval(this.timerAutoReload);
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    date: {
      handler(val) {
        this.notices = []
        this.noticeViewSize = 5
        this.getNotices(val);
      },
      immediate: true,
    },
    workingTimes() {
      this.updateNowX()
    },
    showReservationModal(newVal) {
      DisableScrollBody(newVal)

      return newVal;
    },
    showMergeModal(newVal) {
      DisableScrollBody(newVal)

      return newVal;
    },
  },
  methods: {
    scrollLeft() {
      data = document.getElementById('data-wrapper');
      data.scrollTo(0, 0);
    },
    getHours(date) {
      return moment(date).format('HH')
    },
    getMinutes(date) {
      return moment(date).format('mm')
    },
    autoReload() {
      if (!this.loading) this.$emit('reload', true)
    },
    updateNowX() {
      this.nowX = this.getX(new Date())
      this.$nextTick(() => {
        this.$refs.dataWrapper.scrollTo(this.nowX - this.colW, 0)
      });
    },
    getX(time) {
      const time_moment = moment(time)
      if (this.workingTimes.length < 1) return -1000
      if (time_moment.isBefore(this.timeTableStart)) return 0
      if (time_moment.isAfter(this.timeTableEnd)) return -1000

      return time_moment.diff(moment(this.timeTableStart), 'minutes') / 60 * this.colW
    },
    getWidth(start, stay_minutes) {
      const start_moment = moment(start)
      const end_moment = moment(start).add(stay_minutes, 'minutes')
      if (start_moment.isBefore(this.timeTableStart)) {
        stay_minutes = stay_minutes - moment(this.timeTableStart).diff(start_moment, 'minutes')
      }
      if (end_moment.isAfter(this.timeTableEnd)) {
        stay_minutes = stay_minutes - end_moment.diff(moment(this.timeTableEnd), 'minutes')
      }
      return stay_minutes / 60 * this.colW -1
    },
    newReservation(table, hour, minute, is_block = false) {
      const start_time = moment(this.date).set({hour, minute})
      const wt = this.workingTimes.find(wt => moment(wt.start_time) <= start_time && moment(wt.end_time) > start_time)
      const isToday = moment(this.date).isSame(moment(), 'day')
      this.editReservation = {
        shop_table_ids: [table.id],
        start_time: start_time.format('YYYY-MM-DD HH:mm'),
        last_name: isToday ? 'ウォークイン' : '',
        number_of_people: (table.number_of_people_max - table.number_of_people_min) || 1,
        number_of_adults: (table.number_of_people_max - table.number_of_people_min) || 1,
        reservation_type: isToday ? 'walkin' : 'phone',
        state: isToday ? 'seating' : 'reserved',
        reset_time: wt?.reset_time || 0,
        stay_minutes: wt?.stay_minutes || 120,
        is_block: is_block
      }
      this.showReservationModal = true
    },
    async openReservation(reservation) {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.get(`${slug}/shop_manager/time_table/${reservation.id}`, {})
        .then((response) => {
          this.$emit('loading', false)
          this.editReservation = response.data.reservation
          this.editReservation.overlap = reservation.overlap
          this.editReservation.mergeable_reservations = reservation.mergeable_reservations
          if (this.editReservation.customer_id != null ) {
            this.getCustomer(this.editReservation.customer_id);
          }
          this.showReservationModal = true
        })
        .catch((error) => {
          console.log(error);
          this.$emit('loading', false)
        });
    },
    async openMergeModal(reservation) {
      this.mergeReservation = reservation
      this.showMergeModal = true
    },
    updateLoading(val) {
      this.$emit('loading', val)
    },
    handleScroll() {
      const top = this.$refs.dataWrapper.getBoundingClientRect().top * -1
      this.headerTop = top < 0 ? 0 : top
    },
    async getCustomer(customer_id) {
      this.$emit('loading', true);
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/customer/${customer_id}`;

      await Http.get(path, {

      })
      .then((response) => {
        this.customerEdit = response.data.customer;
        this.reservations = response.data.reservations;
        this.$emit('loading', false)
      })
      .catch((error) => {
        this.$emit('loading', false)
        console.log(error);
      });
    },
    hasNotice(reservationId) {
      return this.notices.some(notice => notice.reservation_id === reservationId);
    },
    async openReservationBlockModal(table) {
      await this.getReservations(table.id)

      this.workingTime = {
        start_time: this.timeTableStart.format('YYYY-MM-DD HH:mm'),
        end_time: this.timeTableEnd.format('YYYY-MM-DD HH:mm'),
      }

      this.editReservation = {
        shop_table_ids: [table.id],
        start_time: this.timeTableStart.format('YYYY-MM-DD HH:mm'),
        end_time: this.timeTableEnd.format('YYYY-MM-DD HH:mm'),
        start_date: this.timeTableStart
      }
      this.showModalReservationBlock = true
    },
    async getReservations(table_id) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/reservation_block/search_block_by_table`;

      await Http.get(path, {
        reserves_date_start: this.timeTableStart.format('YYYY-MM-DD HH:mm:ss'),
        reserves_date_end: this.timeTableEnd.format('YYYY-MM-DD HH:mm:ss'),
        shop_table_id: table_id
      }).then((response) => {
        this.reservationBlocks = response.data.reservations;
        this.datetime_blocks = []
        this.reservationBlocks.forEach((element) => {
          this.datetime_blocks.push({
            id: element.id,
            start_time: moment(element.start_time).format('HH:mm'),
            end_time: moment(element.end_time).format('HH:mm'),
            _destroy: false
          })
        });
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },

    async getNotices(date) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.get(`${slug}/shop_manager/shop_notices`, {
        date: moment(date).format("YYYY-MM-DD"),
        page_size: 1000,
        page: 1,
      })
          .then((response) => {
            const data = response.data
            this.notices = this.notices.concat(data.notices)
          })
          .catch((error) => {
            console.log(error);
          });
    },

    checkReservationBlock(table, indexTable, hour, minute){
      let isReservationBlock = false;
      const start_time = moment(this.date).set({hour, minute})

      this.timeTable[indexTable].reservations.forEach((element) => {
        if (this.timeTable[indexTable].table.id == table.id &&
           moment(element.start) <= start_time && moment(element.end) > start_time && element.state == 'busy') {
            isReservationBlock = true
        }
      })

      return isReservationBlock;
    },
    showPopups(event, el, content) {
      document.querySelectorAll('.memo-popup').forEach((el) => el.remove())
      const popup = document.createElement('div');
      popup.classList.add('memo-popup');
      const titles = ['お店のメモ','予約に関する備考']
      content.map((el, i) => {
        if (el !== '') {
          const paragraph = document.createElement('p')
          const heading = document.createElement('span')
          const bodytext = document.createElement('span')
          heading.innerText = titles[i]
          heading.style.fontWeight = '600'
          bodytext.style.maxWidth = '60'
          paragraph.style.margin = '25'
          paragraph.style.padding = '0';
          paragraph.style.width = '340'
          paragraph.style.paddingBottom = '0'
          bodytext.innerText = el
          paragraph.appendChild(heading)
          paragraph.appendChild(document.createElement('br'))
          paragraph.appendChild(bodytext)
          popup.appendChild(paragraph)
        }
      })
      popup.style.position = 'absolute';
      const ele = event.srcElement.closest('.reservation')
      popup.style.left = `${ele.getBoundingClientRect().left + ele.getBoundingClientRect().width/2}px`;
      popup.style.top = `${ele.getBoundingClientRect().bottom - 15}px`;
      popup.style.background = 'white';
      popup.style.zIndex = '1';

      document.body.appendChild(popup);
      el.$popupElement = popup;
      el.$popupElement.classList.add('.popup')
    },
    hidePopup(event, el) {
      if (el.$popupElement) {
        document.body.removeChild(el.$popupElement);
        delete el.$popupElement;
      }
    },
  },
  directives: {
    'popup': {
      inserted: function (el, binding, vnode) {
        let longPressTimer = null;

        function showPopup(event) {
          longPressTimer = setTimeout(() => {
            vnode.context.showPopups(event, el, binding.value);
          }, 1000); // Adjust the duration for long press (in milliseconds)
        }

        function hidePopup(event) {
          clearTimeout(longPressTimer);
          vnode.context.hidePopup(event, el);
        }

        if (isTouchable()) {
          // Touch events
          el.addEventListener('touchstart', showPopup);
          el.addEventListener('touchend', hidePopup);
          el.addEventListener('touchcancel', hidePopup);

          // Prevent touch events from generating mouse events
          el.addEventListener('touchmove', (event) => {
            event.preventDefault();
          });
        } else {
          // Mouse events
          el.addEventListener('mouseenter', showPopup);
          el.addEventListener('mouseleave', hidePopup);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.timetable {
  display: flex;
  background-color: #FFFFFF;
  min-width: 100%;
  min-height: 300px;
  position: relative;
}

.label-wrapper {
  text-align: center;
  border-right: 1px solid #f4f3ef;
  box-shadow: 2px 0px 8px 0px #00000010 !important;

  .shop-table-name {
    width: 150px;
    border-right: 1px solid #f4f3ef;
    color: #0090d7;
  }

  #shop-table-name {
    .shop-table-name {
      -webkit-line-clamp: 1;
      height: 20px;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }
  }
  .capacity {
    width: 50px;
  }
  .table-row {
    display: flex;
    align-items: center;
    border-top: 1px solid #66615b59;
  }
  .scrollbar-space {
    height: 14px;
    border-top: 1px solid #f4f3ef;
  }
}

.data-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
  position: relative;

  .hour {
    width: var(--colW);
    text-align: center;
    border-right: 1px solid #f4f3ef;
  }
  .scroll-wrapper {
    position: absolute;
    border-bottom: 1px solid #f4f3ef;
  }
  .table-row {
    display: flex;
    border-top: 1px solid #66615b59;
    .cell {
      border-right: 1px solid #f4f3ef;
      width: var(--cellW);
      cursor: pointer;
      &:hover {
        background-color: #f4f3ef;
      }
      &.end-hour {
        border-right: 1px dashed #66615b59;
      }
    }
  }
  .current-time-line {
    height: calc(100% + var(--headerH)*(-1px));
    width: 4px;
    background-color: #FF0000;
    display: block;
    position: absolute;
    top: var(--headerH);
    opacity: 0.4;
  }
  .reservation {
    position: absolute;
    background-color: #FFFFFF;
    min-width: var(--cellW);
    font-size: 12px;
    cursor: pointer;
    border-right: 1px solid #f4f3ef;
    user-select: none;
    -webkit-user-select: none;

    div {
      position: absolute;
    }
    .square-number {
      top: 2px;
      left: 2px;
      background-color: #0090d7;
      min-width: 16px;
      height: 16px;
      color: #FFFFFF;
      display: inline-block;
      text-align: center;
    }
    .table-merge-icon {
      left: 2px;
      bottom: 2px;
      font-size: 11px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 5px);
    }
    .full-name {
      top: 2px;
      left: 2px;
      padding-right: 28px;
      font-size: 11px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &.merge-table {
        left: 25px;
        padding-right: 45px;
        top: 4px;
      }
    }
    .number-of-people {
      top: 2px;
      right: 2px;
    }
    .type {
      right: 2px;
      bottom: 2px;
      font-size: 11px;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 5px);
    }
    &.reserved {
      background: #fff;
      color: #0090d7;
      border: 2px solid #0090d7;
    }
    &.seating {
      background: #0090d7;
      color: #fff;
      border: 2px solid #0090d7;
      .square-number {
        color: #0090d7 !important;
        background: #fff !important;
      }
    }
    &.finished {
      background: #767676;
      color: #fff;
      border: 2px solid #767676;
      .square-number {
        color: #767676 !important;
        background: #fff !important;
      }
    }
    &.busy {
      background-color: #cccccc;
      opacity: 0.5 !important;
      z-index: 0;
    }
    &.not_show {
      background: #fff;
      color: #B96E71;
      border: 2px solid #B96E71;
      .square-number {
        color: #fff !important;
        background: #B96E71 !important;
      }
    }
    @keyframes border-blink { 50% { border-color:transparent ; }  }
    &.overlap {
      border: solid 2px #DD5858;
      animation: border-blink 1.5s;
      animation-iteration-count: infinite;
    }
  }
}

.label-wrapper, .data-wrapper {
  .table-header {
    display: flex;
    background-color: #0090d7;
    color: #FFFFFF;
    font-weight: 600;
    position: absolute;
    z-index: 10;
  }
}

.loading {
  z-index: 3000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffa1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-while {
  color: #fff !important;
}
.reservation {
  font-weight: bold;
  &:hover {
    opacity: 0.7;
  }
}
.opacity-unset {
  opacity: unset !important;
}

.reservation_busy {
  background-color: #cccccc !important;
  opacity: 0.5 !important;
}
.my-fork{
  background: #03af7a;
  padding: 2px;
  border-radius: 50%;
  margin: 2px;
}
.my-bell{
  background: #005aff;
  padding: 2px;
  border-radius: 50%;
  margin: 2px;
}
</style>
