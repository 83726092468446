<template>
  <div class="modal fade show" id="form-allergy" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">お客様属性登録</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <input 
                v-model="nameProperty"
                type="text"
                class="form-control"
              >
              <small v-for="error in errors['name']" :key="error" class="error">{{ error }}</small>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="createProperty">登録する</button>
          <button type="button" class="btn btn-outline-primary" @click="onClose">閉じる</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>
<script>
import Http from "../../../shared/http"
import Validator from "../../../src/validator"
export default {
  props: {
    getProperties: {
      type: Function,
    },
  },
  data() {
    return {
      nameProperty: null,
      errors: {},
    }
  },
  methods: {
    onClose() {
      this.$emit('close-modal-allergy');
    },
    async createProperty() {
      this.validate();
      if (Object.keys(this.errors).length) {
        return;
      }
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/properties`;
      const params = {
        name: this.nameProperty
      }

      await Http.post(path, params)
        .then((response) => {
          this.$emit('loading', false)
          this.getProperties();
          this.$emit('close-modal-allergy');
        })
        .catch((error) => {
          this.$emit('loading', false)
          this.errors = error.response.data;
        });
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('name', this.nameProperty, 'お客様属性') &&
        validator.checkMaxLength('name', this.nameProperty, 255, 'お客様属性');
      
      this.errors = validator.errors;
    }
  }
}
</script>
<style scoped>
#form-allergy .modal-dialog {
  z-index: 1055;
}
.btn-primary {
  border: 2px solid #0090d7 !important;
}
</style>