<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">POSデータと結合</h5>
          <button type="button" class="close" @click="onClose"><span>&times;</span></button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <v-select v-model="mergeReservation"
                :options="reservation.mergeable_reservations"
                :get-option-label="r => `${moment(r.start).format('HH:mm')} - ${moment(r.end).format('HH:mm')} ${r.type_label}`"
                :clearable="false" :searchable="false"
              />
            </div>
          </div>
          <div class="row mt_medium">
            <div class="col-md-12">
              {{getTableName(reservation.shop_table_ids)}}のPOSレジ連携情報を以下の通り連動しますか？
            </div>
            <div class="col-md-12 mt_medium">［現在］</div>
            <div class="col-md-12" id="willBeMergedSche">
              {{`${reservation.reservation_type_label}：${moment(reservation.start_time).format("HH:mm")} - ${moment(reservation.end_time).format("HH:mm")}`}}
            </div>
            <div class="col-md-12" id="mergeWithSche" v-if="mergeReservation">
              {{`${mergeReservation.type_label}：${moment(mergeReservation.start).format("HH:mm")} - ${moment(mergeReservation.end).format("HH:mm")}`}}
            </div>
            <div class="col-md-12 mt_medium">［設定後］</div>
            <div class="col-md-12" id="afterMergeSche" v-if="mergeReservation">
              {{`${mergeReservation.type_label}：${moment(mergeReservation.start).format("HH:mm")} - ${moment(mergeReservation.end).format("HH:mm")}`}}
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary mr_small" @click="onSave">登録する</button>
          <button type="button" class="btn btn-outline-primary" @click="onClose">閉じる</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import Http from "../../../shared/http"
import {decode_data} from "../../../shared/util"
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {vSelect},
  props: {
    reservation: {
      type: Object,
    },
  },
  data() {
    return {
      shop_tables: decode_data("#data-shop-tables"),
      mergeReservation: null,
    };
  },
  computed: {
  },
  watch: {
    reservation: {
      handler(reservation) {
        this.mergeReservation = reservation?.mergeable_reservations?.[0];
      },
      deep: true,
    },
  },
  methods: {
    moment,
    onClose() {
      this.$emit('close');
    },
    findTable(id) {
      return this.shop_tables.find(table => table.id == id);
    },
    getTableName(ids) {
      if (!ids) return "";
      return ids.map(id => this.findTable(id).name).join(", ");
    },
    async onSave() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      await Http.put(`${slug}/shop_manager/time_table/${this.reservation.id}/merge_reservation`, {
        merge_reservation_id: this.mergeReservation.id,
      })
        .then((response) => {
          this.$emit('reload')
          this.$emit('close')
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
    .modal-header, .modal-body {
    padding: 0.6rem 1rem;
  }
  .modal-footer {
    padding: 0rem 1rem;
  }
  .modal-dialog {
    z-index: 1050;
    max-width: 560px;
  }
</style>
