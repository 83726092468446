<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">席{{ isCreateNew ? '登録' : '更新'}}</h5>
          <button type="button" class="close close_reservation_modal" @click="onClose"><span>&times;</span></button>
        </div>
        <div class="modal-body pd-0" id="modal-body">
          <template v-if="isCreateNew">
            <div class="tab_area">
              <a class="tab order_manage btn_point cursor-pointer"
                :class="mode === 'walkin' ? 'active' : ''"
                @click="mode = 'walkin'"
              >ウォークイン登録</a>
              <a class="tab order_manage btn_point cursor-pointer"
                :class="mode === 'normal' ? 'active' : ''"
                @click="mode = 'normal'"
              >予約登録</a>
            </div>
          </template>
          <template v-else>
            <div class="tab_area">
              <a class="tab order_manage btn_point cursor-pointer"
                :class="tab === 'reservation' ? 'active' : ''"
                @click="tab = 'reservation'"
              >予約登録</a>
              <a v-if="reservation.customer_id !== null"
                class="tab order_manage btn_point cursor-pointer"
                :class="tab === 'customer' ? 'active' : ''"
                @click="tab = 'customer'"
              >顧客情報</a>
              <a v-if="reservation.pos_order_data" class="tab order_manage btn_point cursor-pointer"
                :class="tab === 'pos_order' ? 'active' : ''"
                @click="tab = 'pos_order'"
              >喫食情報</a>
              <a v-if="reservation.ses_import_data" class="tab order_manage btn_point cursor-pointer"
                :class="tab === 'ses_mail' ? 'active' : ''"
                @click="tab = 'ses_mail'"
              >予約メール</a>
            </div>
          </template>
          <template v-if="mode==='normal' && tab==='reservation'">
            <div class="form-modal-reservation">
              <div class="row mt_small">
                <div class="col-md-12">
                  <label>ステータス<span class="require">必須</span></label>
                  <div class="d-flex py-2">
                    <button v-for="item in state_options" :key="item.value" class="button_state unhover mr-0 cursor-pointer" :class="item.value"
                      v-if="state==item.value" @click="showStatus = reservation.state == 'cancelled' ? false : true">
                      <div class="text_state">
                        {{ item.text }}
                      </div>
                    </button>
                    <a class="flex-item-center cursor-pointer" id="openStatus" v-show="reservation.state != 'cancelled'" style="color: blue; padding-left: 20px;" @click="showStatus=true">変更する</a>
                  </div>
                  <div v-if="showWarning" class="error">更新ボタンを押すとキャンセルされます。予約の復元・確認は、［予約情報］-［予約一覧］から行えます。</div>
                  <div class="modal show" style="display: block;" v-if="showStatus" @click="showStatus=false">
                    <div class="modal-dialog modal-dialog-scrollable">
                      <div class="modal-content modal-mb" @click.stop="">
                        <div class="modal-body">
                          <div class="box_list">
                            <div class="list_status">
                              <div class="row" style="padding-left: 20px; padding-bottom: 10px;">
                                変更するステータスを選択してください。
                              </div>
                              <div class="d-flex" style="width: 100%">
                                <button v-for="item in state_options" :key="item.value"
                                        @click="changeState(item)"
                                        class="button_state" :class="item.value"
                                        v-if="state!=item.value"
                                >
                                  <div class="text_state">
                                    {{ item.text }}
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small v-for="error in errors['state']" :key="error" class="error">{{ error }}</small>
                </div>
              </div>
            </div>
          </template>
          <div v-if="tab === 'reservation'" class="form-modal-reservation">
            <div class="row mt_small">
              <div class="col-md-12">
                <label>予約種別<span class="require">必須</span></label>
                <select v-model="is_course" class="form-control customer_select cursor-pointer">
                  <option value="0">席のみ</option>
                  <option value="1">コース</option>
                </select>
              </div>
            </div>
            <div class="row mt_small">
              <div class="col-md-12">
                <label>コース名<span v-if="is_course==1" class="require">必須</span></label>
                <v-select v-model="course_id"
                  :options="courses"
                  :reduce="o => o.id"
                  label="name"
                  @input="changeStayTime"
                  :class="is_course == '0' ? 'disable course_name' : 'course_name'"
                  placeholder="コースを選択してください。"
                  ref="select"
                >
                  <span slot="no-options" @click="$refs.select.open = false">
                    表示するデータはありません。
                  </span>
                </v-select>
                <small v-for="error in errors['course_ids']" :key="error" class="error">{{ error }}</small>
              </div>
            </div>
            <div v-if="course_id != null" class="control_area">
              <div class="reservation_type">
                <span>コース内容</span>
              </div>
              <div class="course_infor">
                <template v-if="course.first_image">
                  <div class="image_course">
                    <img class="image_set" :src="course.first_image">
                  </div>
                </template>
                <template v-else>
                  <div class="image_course">
                    <img class="image_set" :src="getImageDefault()">
                  </div>
                </template>
                <div class="detail_course">
                  <div class="d-flex" style="flex-wrap: wrap; margin-top: 20px">
                    <div v-for="name in course.category_name" :key="name" class="type_course">
                      <span class="span_course">{{name}}</span>
                    </div>
                  </div>
                  <div class="body_course" style="font-size: 16px;">
                    {{course.name}}
                  </div>
                  <div class="title_course">
                    <div class="price_course">
                      <span style="font-size: 16px;">{{course.price}}</span><span style="font-size: 12px;">円（税込）</span>
                    </div>
                    <div class="footer_course">
                      <div class="cursor-pointer" @click="showCourseModal=true">詳細を見る</div>
                    </div>
                  </div>
                </div>
              </div>
              <small style="display: block; margin-left: 20px; margin-top: 5px"
                    v-if="reservation.state=='cancelled'"
                    v-for="error in errors['course_ids']" :key="error" class="error">{{ error }}</small>
            </div>
            <div class="row mt_small">
              <div class="col-md-6">
                <label>テーブル<span class="require">必須</span></label>
                <v-select v-model="shop_table_ids"
                  :options="shop_tables"
                  :reduce="o => o.id"
                  label="full_name"
                  :clearable="false" :searchable="false"
                  :multiple="true"
                  class="form_select_table"
                >
                  <template slot="selected-option" slot-scope="option">
                    <span class="single_line first_span">
                      {{ option.name }}
                    </span>
                    <span class="second_span">[{{option.number_of_people_min}}-{{option.number_of_people_max}}人]</span>
                  </template>
                </v-select>
                <small v-for="error in errors['shop_table_ids']" :key="error" class="error">{{ error }}</small>
              </div>
              <div class="col-md-6">
                <label>滞在分<span class="require">必須</span></label>
                <select class="form-control customer_select cursor-pointer" v-model="stay_minutes">
                  <option v-if="!(new Array(16).fill(0).map((_, i) => i * 15 + 15)).includes(stay_minutes_option)" :value="stay_minutes_option" style="display: none;">{{stay_minutes_option}}</option>
                  <option v-for="item in new Array(16).fill(0).map((_, i) => i * 15 + 15)" :value="item" :key="item">{{ item }}</option>
                </select>
                <small v-for="error in errors['stay_minutes']" :key="error" class="error">{{ error }}</small>
              </div>
            </div>
            <div class="row mt_small">
              <div class="col-md-6">
                <label>人数（大人）<span class="require">必須</span></label>
                <select class="form-control cursor-pointer" v-model="number_of_adults" @change="changeNumberPeople">
                  <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">{{ item }}</option>
                </select>
              </div>
              <div class="col-md-6">
                <label>人数（子供）</label>
                <select class="form-control cursor-pointer" v-model="number_of_children" @change="changeNumberPeople">
                  <option :value="0"></option>
                  <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">{{ item }}</option>
                </select>
              </div>
            </div>
            <div class="row" style="margin-top: 4px;">
              <div class="col-md-12">
                <span style="font-weight: 600;">会計人数： {{ number_of_people }}人</span>
              </div>
              <div class="col-md-12">
                <small v-for="error in errors['number_of_people']" :key="error" class="error">{{ error }}</small>
              </div>
            </div>
            <div class="row mt_small">
              <div class="col-md-6">
                <label>日付<span class="require">必須</span></label>
                <DatePicker
                  input-class="form-control cursor-pointer"
                  v-model="start_date"
                  value-type="format"
                  format="YYYY-MM-DD"
                  :clearable="false">
                  <i slot="icon-calendar"></i>
                </DatePicker>
              </div>
              <div class="col-md-6">
                <label>時分<span class="require">必須</span></label>
                <DatePicker
                  input-class="form-control cursor-pointer"
                  v-model="start_time"
                  value-type="format"
                  format="HH:mm"
                  type="time"
                  :show-second="false"
                  :minute-step="15"
                  :clearable="false">
                  <i slot="icon-calendar"></i>
                </DatePicker>
                <small v-for="error in errors['time']" :key="error" class="error">{{ error }}</small>
              </div>
              <div class="col-md-12">
                <small v-for="error in errors['start_time']" :key="error" class="error">{{ error }}</small>
              </div>
            </div>
            <template v-if="isCreateNew && mode === 'normal'">
              <div class="row mt_small">
                <div class="col-md-12">
                  <label>お客様情報検索： お名前(姓・名)または電話番号</label>
                  <v-select
                    class="cursor-pointer"
                    v-model="customer"
                    :options="customers"
                    label="customer_data"
                    @input="onChange"
                    v-on:change="onChange"
                    ref="select"
                  >
                    <template v-slot:search="scope">
                      <input
                        class="vs__search"
                        v-bind="scope.attributes"
                        v-on="scope.events"
                        v-model="searchCustomerKeyword"
                        @input="onSearchCustomer()"
                      />
                    </template>
                    <span slot="no-options" @click="$refs.select.open = false">
                      表示するデータはありません。
                    </span>
                  </v-select>
                </div>
              </div>
            </template>
            <template v-if="mode === 'normal'">
              <div class="row mt_small">
                <div class="col-md-6">
                  <label>お名前(姓)<span class="require">必須</span></label>
                  <input type="text" class="form-control" v-model="last_name">
                  <small v-for="error in errors['last_name']" :key="error" class="error">{{ error }}</small>
                </div>
                <div class="col-md-6">
                  <label>お名前(名)</label>
                  <input type="text" class="form-control" v-model="first_name">
                  <small v-for="error in errors['first_name']" :key="error" class="error">{{ error }}</small>
                </div>
              </div>
              <div class="row mt_small">
                <div class="col-md-6">
                  <label>お名前(セイ)</label>
                  <input type="text" class="form-control" v-model="last_name_kana">
                  <small v-for="error in errors['last_name_kana']" :key="error" class="error">{{ error }}</small>
                </div>
                <div class="col-md-6">
                  <label>お名前(メイ)</label>
                  <input type="text" class="form-control" v-model="first_name_kana">
                  <small v-for="error in errors['first_name_kana']" :key="error" class="error">{{ error }}</small>
                </div>
              </div>
              <div class="row mt_small">
                <div class="col-md-12">
                  <label>電話番号</label>
                  <input type="tel" class="form-control" v-model="tel" pattern="[+0-9]*" inputmode="numeric">
                  <small v-for="error in errors['tel']" :key="error" class="error">{{ error }}</small>
                </div>
              </div>
              <div class="row mt_small">
                <div class="col-md-12" @click="checkStaffName();">
                  <label>メールアドレス</label>
                  <input type="text" class="form-control" v-model="email">
                  <small v-for="error in errors['email']" :key="error" class="error">{{ error }}</small>
                </div>
              </div>
              <div class="row mt_small">
                <div class="col-md-6">
                  <label>予約担当者</label>
                  <select class="form-control cursor-pointer" v-model="staff_name">
                    <option v-if="staff_name_deleted !=null" :value="staff_name_deleted" style="display: none">{{staff_name_deleted}}</option>
                    <option v-for="item in reservation_staffs" :value="item.name" :key="item.id">{{ item.name }}</option>
                  </select>
                  <span slot="no-options"></span>
                  <small v-for="error in errors['staff_name']" :key="error" class="error">{{ error }}</small>
                </div>
                <div class="col-md-6">
                  <label>流入経路<span class="require">必須</span></label>
                  <select class="form-control cursor-pointer" id="reservation-types" v-model="reservation_type">
                    <option v-for="item in reservation_type_options"
                      :value="item.value"
                      :key="item.value"
                      v-if="item.value != 'pos' || is_shop_pos"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                  <small v-for="error in errors['reservation_type']" :key="error" class="error">{{ error }}</small>
                </div>
              </div>
            </template>
            <div v-if="!isCreateNew" class="row mt_small">
              <div class="col-md-6">
                <label>受付日時</label>
                <input type="text" class="form-control disable" v-model="createdAt">
              </div>
              <div class="col-md-6">
                <label>更新日時</label>
                <input type="text" class="form-control disable" v-model="updatedAt">
              </div>
            </div>
            <div class="row mt_small">
              <div class="col-md-12">
                <label>予約に関する備考</label>
                <textarea class="form-control" v-model="memo_from_customer" rows="2"></textarea>
                <small v-for="error in errors['memo_from_customer']" :key="error" class="error">{{ error }}</small>
              </div>
            </div>
            <div class="row mt_small">
              <div class="col-md-12">
                <label>お店のメモ</label>
                <textarea class="form-control" v-model="memo" rows="2"></textarea>
                <small v-for="error in errors['memo']" :key="error" class="error">{{ error }}</small>
              </div>
            </div>
          </div>

          <CustomerForm v-show="tab === 'customer'" ref="customerForm" :customer="customerEdit" :reservations="reservations" 
                        :isCreateNew="isCreateNew" @changeData="isChangeData = true" @loading="$emit('loading', $event)"
                        @submitSuccess="submitSuccess">
          </CustomerForm>

          <div v-if="tab == 'pos_order'">
            <div v-if="reservation.customer_id != null">
              <div class="header-res" style="border-bottom: 1px solid #DEE2E6;background: #0090d7;color: #fff;">
                <div class="col-res-1" style="margin: 10px 0 10px 30px;">
                  日付
                </div>
                <div class="col-res-2" style="margin: 10px 0 10px 30px;">
                  品数
                </div>
                <div class="col-res-3" style="margin: 10px 0 10px 30px;">
                  飲食金額
                </div>
                <div class="col-res-4" style="margin: 10px 0 10px 30px;">
                </div>
              </div>
              <div v-for="(r, index) in reservations" v-bind:key="index">
                <div v-if="r.pos_order_data">
                  <button class="accordion" :id="'accordion-' + index" :key="index" @click="showItemPosData($event, index)">
                    <div class="header-res">
                      <div class="col-res-1" style="margin: 10px 0 10px 30px;">
                        {{ convertTime(r.start_time) }}
                      </div>
                      <div class="col-res-2" style="margin: 10px 0 10px 30px;">
                        {{ r.pos_order_data.items.length > 0 ? r.pos_order_data.quantity : '' }}
                      </div>
                      <div v-if="r.pos_order_data.items.length > 0" class="col-res-3" style="margin: 10px 0 10px 30px;">
                        ￥{{ Intl.NumberFormat('ja-JP').format(r.pos_order_data.sum) }}
                      </div>
                      <div v-else class="col-res-3" style="margin: 10px 0 10px 30px;"></div>
                      <div class="col-res-4 icon-acc">
                      </div>
                    </div>
                  </button>
                  <div class="panel" :id="'panel-' + index" style="margin: 0 30px;">
                    <div style="margin-top: 10px; margin-bottom: 10px;">伝票番号：{{ r.pos_order_data.order_no }}</div>
                    <div v-for="item in r.pos_order_data.items" v-bind:key="item.name">
                      <div class="header-res">
                        <div class="col-res-1 w-30-res">
                          {{ item.name }}
                        </div>
                        <div class="col-res-2">
                          {{ item.quantity }}
                        </div>
                        <div class="col-res-3 w-27-res">
                          ￥{{ Intl.NumberFormat('ja-JP').format(item.price) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="border-bottom-res"></div>
                </div>
              </div>
            </div>
            <div v-else style="margin: 0 30px;">
              <div class="info_adjust-item">
                <div style="margin-top: 10px; margin-bottom: 10px;">伝票番号：{{ reservation.pos_order_data.order_no }}</div>
                <div style="margin-top: 10px; margin-bottom: 10px;">会計時刻：{{ formartPaymentDT(reservation.pos_order_data.payment_dttm) }}</div>
                <div class="item-container">
                  <div v-for="item in reservation.pos_order_data.items" v-bind:key="item.name">
                    <div class="header-res">
                      <div class="col-res-1 w-30-res ml-0">
                        {{ item.name }}
                      </div>
                      <div class="col-res-2">
                        {{ item.quantity }}
                      </div>
                      <div class="col-res-3 w-27-res">
                        ￥{{ Intl.NumberFormat('ja-JP').format(item.price) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="header-res">
                  <div class="col-res-1 w-30-res ml-0">
                    会計金額
                  </div>
                  <div class="col-res-2"></div>
                  <div class="col-res-3 w-27-res">
                    ￥{{ Intl.NumberFormat('ja-JP').format(reservation.pos_order_data.sum) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-modal-reservation" v-if="tab == 'ses_mail'">
            <div class="row mt_small">
              <div class="col-md-6">
                <label>自動連携日時</label>
                <input type="text" class="form-control" v-model="reservation.ses_import_data.received_at">
              </div>
              <div class="col-md-6">
                <label>流入経路</label>
                <input type="text" class="form-control" v-model="reservation.ses_import_data.from_system">
              </div>
              <div class="col-md-12 mt_small">
                <label>メール本文</label>
                <textarea class="form-control" rows="18" style="max-height: none; line-height: 1.4rem; font-size: 14px;" v-html="reservation.ses_import_data.content_mail"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button v-if="reservation.state == 'cancelled'" type="button" class="btn btn-primary mr_small" @click="onRestore">復元</button>
          <button v-else type="button" class="btn btn-primary mr_small" @click="onSave">{{ isCreateNew ? '登録' : '更新'}}する</button>
          <button type="button" class="btn btn-outline-primary btn-unset" @click="onClose">閉じる</button>
        </div>
      </div>
    </div>
    <CourseModal
      v-if="showCourseModal && tab==='reservation'"
      :course="course"
      @close="showCourseModal = false"
    >
    </CourseModal>
    <ModalConfirmClose
      v-if="showModalConfirmClose"
      @close="showModalConfirmClose=false"
      @confirm="onConfirmClose"
    >
    </ModalConfirmClose>
  </div>
</template>

<script>
import moment from 'moment';
import {decode_data} from "../../shared/util";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Http from "../../shared/http"
import CourseModal from './CourseModal.vue';
import ModalConfirmClose from './ModalConfirmClose.vue'
import Validator from "../../src/validator"
import _ from 'lodash';
import CustomerForm from './CustomerForm.vue';
import ErrorModalService from "../../src/services/errorModalService";

export default {
  components: { DatePicker, vSelect, CourseModal, ModalConfirmClose, CustomerForm },
  props: {
    reservation: {
      type: Object,
    },
    customerEdit: {
      type: Object,
    },
    reservations: {
      type: Array,
    }
  },
  data() {
    return {
      course: {},
      showWarning: false,
      showModalStatus: false,
      showAllReservation: false,
      showStatus: false,
      showCourseModal: false,
      course_id: null,
      customer_id: null,
      customer: {},
      customers_search: [],
      tab: 'reservation',
      mode: '',
      is_course: 0,
      shop_table_ids: [],
      number_of_people: '',
      number_of_adults: '',
      number_of_children: '',
      stay_minutes: '',
      stay_minutes_option: '',
      start_date: '',
      start_time: '',
      reservation_type: '',
      last_name: '',
      first_name: '',
      last_name_kana: '',
      first_name_kana: '',
      tel: '',
      email: '',
      memo: '',
      memo_from_customer: '',
      createdAt: null,
      updatedAt: null,
      staff_name: '',
      staff_name_deleted: '',
      is_staff_name: false,
      state: '',
      errors: {},
      client_name: '',
      is_shop_pos: false,
      is_tabelog_sqs: false,
      is_email_reservation: false,
      shop_tables: decode_data("#data-shop-tables"),
      reservation_staffs: decode_data("#data-reservation-staffs"),
      shop: decode_data("#data-shop"),
      reservation_type_options: [
        {
          text: '電話',
          value: 'phone',
        },
        {
          text: '管理者',
          value: 'admin',
        },
        {
          text: 'Table Request',
          value: 'tablerequest',
        },
        {
          text: 'Instagram',
          value: 'instagram',
        },
        {
          text: 'Google',
          value: 'google',
        },
        {
          text: 'ウォークイン',
          value: 'walkin',
        },
        {
          text: 'POSレジ連携',
          value: 'pos',
        },
        {
          text: 'ぐるなび',
          value: 'gnavi',
        },
        {
          text: 'ホットペッパー',
          value: 'hotpepper',
        },
        {
          text: '一休',
          value: 'ikyu',
        },
        {
          text: '食べログ',
          value: 'tabelog',
        },
        {
          text: 'Tablecheck',
          value: 'tablecheck',
        },
        {
          text: 'Hitosara',
          value: 'hitosara',
        },
        {
          text: 'トレタ',
          value: 'toreta',
        },
        {
          text: 'Line',
          value: 'line',
        },
        {
          text: 'Alipay',
          value: 'alipay',
        }
      ],
      state_options: [
        {
          text: '予約',
          value: 'reserved',
        },
        {
          text: '着席',
          value: 'seating',
        },
        {
          text: '会計済',
          value: 'finished',
        },
        {
          text: 'No Show',
          value: 'not_show',
        },
        {
          text: 'キャンセル',
          value: 'cancelled',
        },
      ],
      courses: [],
      showPosData: false,
      showModalConfirmClose: false,
      isCustomerChangeData: false,
      isChangeData: false,
      reservationDefault: {},
      searchCustomerKeyword: null,
      customers: [],
    };
  },
  computed: {
    isCreateNew() {
      return !this.reservation.id;
    },
  },
  watch: {
    reservation: {
      handler(r) {
        this.mode = this.isCreateNew && r.reservation_type === 'walkin' ? 'walkin' : 'normal'
        this.shop_table_ids = r.shop_table_ids
        this.number_of_adults = r.number_of_adults === 0 ? r.number_of_people : r.number_of_adults
        this.number_of_children = r.number_of_children ? r.number_of_children : 0
        this.number_of_people = r.number_of_people
        this.stay_minutes = r.stay_minutes
        this.stay_minutes_option = r.stay_minutes
        this.start_date = moment(r.start_time).format('YYYY-MM-DD')
        this.start_time = r.is_block ? null : moment(r.start_time).format('HH:mm')
        this.reservation_type = r.reservation_type
        this.last_name = r.last_name
        this.first_name = r.first_name
        this.last_name_kana = r.last_name_kana
        this.first_name_kana = r.first_name_kana
        this.tel = r.tel
        this.email = r.email
        this.memo = r.memo
        this.memo_from_customer = r.memo_from_customer
        this.staff_name = r.staff_name
        this.reservation_type = r.reservation_type
        this.state = r.state
        this.client_name = r.client_name
        this.course_id = r.course_id
        this.is_tabelog_sqs = r.is_tabelog_sqs;
        this.is_email_reservation = r.is_email_reservation;
        if (this.course_id) {
          this.getCourse(this.course_id)
          this.is_course = 1
        }
        else {
          this.is_course = 0
        }
        this.createdAt = moment(r.created_at).format('YYYY-MM-DD HH:mm')
        this.updatedAt = moment(r.created_at).format('YYYY-MM-DD HH:mm:ss') !== moment(r.updated_at).format('YYYY-MM-DD HH:mm:ss') ? moment(r.updated_at).format('YYYY-MM-DD HH:mm') : ''

        if (this.reservation_type == 'web') {
          this.reservation_type = 'tablerequest'
        }

        this.tab = 'reservation';
        this.showCourseModal = false;
        this.showAllReservation = false;
        this.showStatus = false;
        this.showModalStatus = false;
        this.showWarning = false;
        this.errors = {}
        this.customer = {}
        this.customer_id = null
        this.checkStaffName()
        this.scrollTopScreen()
        this.showModalConfirmClose = false
        this.isChangeData = false

        this.reservationDefault.reservation_type = this.reservation_type
        this.reservationDefault.state = this.state
        this.reservationDefault.last_name = this.last_name
      },
      deep: true,
    },

    shop_table_ids(newValue) {
      if (newValue != this.reservation.shop_table_ids) {
        this.isChangeData = true;
      }
    },

    course_id(newValue) {
      if (newValue != this.reservation.course_id) {
        this.isChangeData = true;
      }
    },

    stay_minutes(newValue) {
      if (newValue != this.reservation.stay_minutes) {
        this.isChangeData = true;
      }
    },

    number_of_adults(newValue) {
      if (newValue != (this.reservation.number_of_adults === 0 ? this.reservation.number_of_people : this.reservation.number_of_adults)) {
        this.isChangeData = true;
      }
    },

    number_of_children(newValue) {
      if (newValue != (this.reservation.number_of_children ? this.reservation.number_of_children : 0)) {
        this.isChangeData = true;
      }
    },

    start_time(newValue) {
      if (newValue != moment(this.reservation.start_time).format('HH:mm')) {
        this.isChangeData = true;
      }
    },

    first_name(newValue) {
      if (newValue != this.reservation.first_name) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    last_name_kana(newValue) {
      if (newValue != this.reservation.last_name_kana) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    first_name_kana(newValue) {
      if (newValue != this.reservation.first_name_kana) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    tel(newValue) {
      if (newValue != this.reservation.tel) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    email(newValue) {
      if (newValue != this.reservation.email) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    staff_name(newValue) {
      if (newValue != this.reservation.staff_name) {
        this.isChangeData = true;
      }
    },

    memo(newValue) {
      if (newValue != this.reservation.memo) {
        this.isChangeData = true;
      }
    },

    last_name(newValue) {
      if (newValue != this.reservationDefault.last_name) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    reservation_type(newValue) {
      if (newValue != this.reservationDefault.reservation_type) {
        this.isChangeData = true;
      }
    },

    state(newValue) {
      if (newValue != this.reservationDefault.state) {
        this.isChangeData = true;
      }
    },

    mode(newValue, oldValue) {
      if (this.isCreateNew && oldValue === 'walkin' && newValue === 'normal') {
        this.reservation_type = 'phone'
        this.last_name = ''
        this.state = 'reserved'
      }
      if (this.isCreateNew && oldValue === 'normal' && newValue === 'walkin') {
        this.reservation_type = 'walkin'
        this.last_name = 'ウォークイン'
        this.state = 'seating'
      }
      this.reservationDefault.reservation_type = this.reservation_type
      this.reservationDefault.state = this.state
      this.reservationDefault.last_name = this.last_name
    },
    async start_date(val) {
      await this.getCourses(val);
      const course_id_select = this.course_id;
      const course = this.courses.filter(function (cours) {
        return (cours.id == course_id_select);
      })

      this.course_id = course.length > 0 ? this.course_id : null;

      if (val != moment(this.reservation.start_time).format('YYYY-MM-DD')) {
        this.isChangeData = true;
      }
    },
    is_course(val) {
      if (val == 0) {
        this.course_id = null;
      }
      if (val != (this.course_id ? 1 : 0)) {
        this.isChangeData = true;
      }
    }
  },
  async mounted() {
    await this.checkPost();
    await this.getCustomers();
  },
  methods: {
    onSearchCustomer: _.debounce(async function() {
      await this.getCustomers();
    }, 300),
    async getCustomers() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/customer/reservation_search`;

      await Http.get(path, {
        keyword: this.searchCustomerKeyword
      })
      .then((response) => {
        this.customers = response.data.customers;
        this.customers.map(function (x){
        let textTelAndEmail = '';
        const firstName = x.first_name == null ? '' : x.first_name;
        if ((x.tel == null || x.tel == '') && (x.email == null || x.email == '')) {
          textTelAndEmail = '';
        } else if ((x.tel == null || x.tel == '') && (x.email != null && x.email != '')) {
          textTelAndEmail = `(電話番号未設定 / ${x.email})`
        } else if ((x.tel != null && x.tel != '') && (x.email == null || x.email == '')) {
          textTelAndEmail = `(${x.tel} / メール未設定)`
        } else {
          textTelAndEmail = `(${x.tel} / ${x.email})`
        }
        return x.customer_data = x.last_name + ' ' + firstName + ' ' + textTelAndEmail;
      });
      })
      .catch((error) => {
        console.log(error);
      });
    },
    changeState(state) {
      this.state = state.value;
      this.showWarning = this.state === 'cancelled' ? true : false;
      this.showStatus = false;
    },
    changeNumberPeople() {
      if (this.number_of_adults == null) return;
      this.number_of_people = this.number_of_adults + this.number_of_children;
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('modal-body');
      elmnt.scrollTo(0, 0);
    },
    getImageDefault() {
      let path = window.location.href;
      if (path.includes('https://book.stg.tablerequest.jp/')) {
        return 'https://book-tablerequest-stg.s3.ap-northeast-1.amazonaws.com/images/shops/default_image.png';
      }
      else {
        return 'https://book-tablerequest-prd.s3.ap-northeast-1.amazonaws.com/images/shops/default_image.png';
      }
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    checkStaffName() {
      this.staff_name_deleted = null;
      if (this.reservation_staffs.find(e => e.name == this.staff_name) == null) {
        this.staff_name_deleted = this.staff_name;
      }
    },
    onClose() {
      if (this.isChangeData) {
        this.showModalConfirmClose = true;
      }
      else {
        this.$emit('close');
      }
    },
    onConfirmClose() {
      this.$emit('close');
    },
    onChange() {
      if (this.customer != null) {
        this.customer_id = this.customer.id;
        this.first_name = this.customer.first_name;
        this.last_name = this.customer.last_name;
        this.first_name_kana = this.customer.first_name_kana;
        this.last_name_kana = this.customer.last_name_kana;
        this.tel = this.customer.tel;
        this.email = this.customer.email;
      }
      else {
        this.customer_id = null;
        this.first_name = '';
        this.last_name = '';
        this.first_name_kana = '';
        this.last_name_kana = '';
        this.tel = '';
        this.email = '';
      }

      this.searchCustomerKeyword = null;
      this.getCustomers();
    },
    async checkPost() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/time_table/is_shop_pos`;
      await Http.get(path)
        .then((response) => {
          this.is_shop_pos = response.data.is_shop_pos;
        })
        .catch((error) => {
          console.log(error)
          this.errors = error.response.data
        });
    },

    async onStateChangeToCancel(){
      const { last_name, first_name, shop, start_date, start_time, number_of_people } = this;
      const prompt = await this.$swal({
        title: "お客様へキャンセル通知を送信しますか？",
        html: `
            <div class="swal2-content" style="font-size: 14px; margin: 0 32px; text-align: left; border: 1px solid black; padding: 10px; height: 20em; overflow-y: auto;">
              <p>${last_name || last_name_kana} ${first_name || first_name_kana}様</p>
              <p>${shop.name} です。</p>
              <p>下記ご予約につきまして、誠に勝手ながらお店よりキャンセルさせていただきました。</p>
              <br>
              <p>予約日時：${start_date} ${start_time}</p>
              <p>予約人数：${number_of_people}</p>
              <p>予約者名：${last_name || last_name_kana} ${first_name || first_name_kana}</p>
              <p>店舗名：${shop.name}</p>
              <p>電話番号：${shop.tel}</p>
              <br>
              <p>誠に恐れ入りますが、ご了承のほど何卒お願い申し上げます。</p>
              <br>
              <p>※本メールは送信専用のため、返信内容を確認できません。</p>
              <p>※ご不明な点がございましたら恐れ入りますが、お店に直接ご連絡くださいますようお願い申し上げます。当メールにご返信いただいても、お店には届きません。</p>
            </div>`,
        icon: "warning",
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus',
          cancelButton: 'btn btn-outline-primary btn-fill'
        },
        showCancelButton: true,
        confirmButtonText: "送信する",
        cancelButtonText: "送信しない",
        buttonsStyling: false,
      });

      if (prompt.isConfirmed) {
        await this.save_reservation(true, true);
      }else{
        await this.save_reservation(false, true)
      }
      this.$emit('loading', false);
    },

    async onRestore(){
      this.validate();
      if (Object.keys(this.errors).length) {
        return;
      }

      if (this.is_course == '0' && this.isCreateNew) {
        this.course_id = null;
      }
      this.$emit('loading', true);
      await this.save_reservation();
    },

    async save_reservation(send_mail=false,confirmed = false){
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = this.isCreateNew ? `${slug}/shop_manager/time_table/` : `${slug}/shop_manager/time_table/${this.reservation.id}`

      await Http.post(path, {confirmed: confirmed, reservation: {
          is_course: this.is_course,
          shop_table_ids: this.shop_table_ids,
          start_time: `${this.start_date} ${this.start_time}`,
          stay_minutes: this.stay_minutes,
          number_of_people: this.number_of_people,
          number_of_adults: this.number_of_adults,
          number_of_children: this.number_of_children,
          reservation_type: this.reservation_type == 'app_tablerequest' ? 'web' : this.reservation_type,
          first_name: this.first_name || '',
          last_name: this.last_name,
          first_name_kana: this.first_name_kana,
          last_name_kana: this.last_name_kana,
          tel: this.tel,
          email: this.email,
          staff_name: this.staff_name,
          memo: this.memo,
          memo_from_customer: this.memo_from_customer,
          reset_time: this.reset_time,
          state: this.state,
          course_ids: this.course_id == null ? [] : [this.course_id],
        },
        update_user_data: this.isCustomerChangeData,
        customer_id: this.customer_id,
        send_mail: send_mail
      })
          .then((response) => {
            this.$emit('reload')
            this.$emit('close')
          })
          .catch((error) => {
            this.$emit('loading', false);
            this.errors = error.response.data
          });
    },
    async showMediaDateErrorModal(errors){
      let errorMessages = ''
      for (const [key, value] of Object.entries(errors)) {
        errorMessages += `<p>${value}</p>`
      }
      const confirm = await this.$swal.fire({
        title: '',
        html: ` ${errorMessages}`,
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus',
          cancelButton: 'btn btn-outline-primary btn-fill'
        },
        icon:'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "変更する",
        cancelButtonText: "キャンセル",
        buttonsStyling: false,
      });
      if (confirm.isConfirmed) {
        await this.save_reservation(false, false);
      }
      else{
        this.$emit('reload')
        this.$emit('close')
      }
    },
    async showMediaErrorModal(errors){
      let errorMessages = ''
      for (const [key, value] of Object.entries(errors)) {
        errorMessages += `<p>${value}</p>`
      }
      const confirm = await this.$swal.fire({
        title: '',
        html: ` ${errorMessages}`,
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus',
          cancelButton: 'btn btn-outline-primary btn-fill'
        },
        icon:'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "変更する",
        cancelButtonText: "キャンセル",
        buttonsStyling: false,
      });
      if (confirm.isConfirmed) {
        await this.save_reservation(true, true);
      }
    },
    async showErrorModal(errors){
      let errorMessages = ''
      for (const [key, value] of Object.entries(errors)) {
        errorMessages += `<p>${value}</p>`
      }
      const confirm = await this.$swal.fire({
        title: 'エラー',
        html: ` ${errorMessages}`,
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus',
          cancelButton: 'btn btn-outline-primary btn-fill'
        },
        icon:'warning',
        allowOutsideClick: false,
        confirmButtonText: "保存",
        buttonsStyling: false,
      });
      if (confirm.isConfirmed) {
        return
      }
    },
    async onSave() {
      if (this.tab === 'customer') {
        const customerForm = this.$refs.customerForm;
        customerForm.onSave();
        return;
      }
      this.validate();
      if (Object.keys(this.errors).length) {
        return;
      }

      if (this.is_course == '0' && this.isCreateNew) {
        this.course_id = null;
      }
      this.$emit('loading', true);

      if (!this.is_tabelog_sqs && (this.state === 'cancelled' || this.state === 'not_show') && !(this.email === null || this.email === '')) {
        await this.onStateChangeToCancel();
      } else {
        await this.save_reservation(false, false);
        if(Object.keys(this.errors).length > 0){
          await this.showUpdateError(this.errors)
        }

      }

      if(this.fromWhere==='fromTimeTable'){
        if(!(Object.keys(this.errors).length)){
          this.showEditForm=false;
        }
      }
    },
    async showUpdateError(errors){
      const errorModalService = new ErrorModalService(errors)
      if(!errorModalService.isMediaError){
        return
      }
      if(errorModalService.blankError) {
        this.reloadComponent()
      }else{
        const alertModal = errorModalService.getAlertType()
        const confirm = await this.$swal.fire({
          title: alertModal.title,
          html: alertModal.text,
          customClass: {
            content: 'swal2-content',
            confirmButton: 'btn btn-primary btn-fill btn-unfocus',
            cancelButton: 'btn btn-outline-primary btn-fill'
          },
          icon:'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: alertModal.confirmButton,
          cancelButtonText: alertModal.cancelButton,
          buttonsStyling: false,
        });
        if (confirm.isConfirmed) {
          if(errorModalService.isMediaError){
            await this.save_reservation(true, true);
          }else{
            return
          }
        }

        }
      },
    displayAllergy(allergy_name, allergy_remark) {
      var name = '';
      allergy_name.forEach(element => {
        if ( element != 'その他') {
          name = name + element + '、';
        }
      });
      if (allergy_remark != null) {
        name = name + allergy_remark;
      }
      if (name[name.length] == '、') {
        name = name.slice(0, -1);
      }
      return name;
    },
    displayProperties(property_name) {
      var name = '';
      property_name.forEach(element => {
        name = name + element + '、';
      });
      if (name[name.length-1] == '、') {
        name = name.slice(0, -1);
      }
      return name;
    },
    async onDelete() {
      const prompt = await this.$swal({
        title: "予約をキャンセルしますか？",
        html: '<p class="swal2-content" style="font-size: 14px; margin: 0 32px;">キャンセルをした予約を復元する場合は、メニューの「予約一覧」から行えます。</p>',
        icon: "warning",
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-danger mr_small',
          cancelButton: 'btn btn-outline-primary'
        },
        showCancelButton: true,
        confirmButtonText: "はい",
        cancelButtonText: "いいえ",
        buttonsStyling: false,
      });
      if (prompt.isConfirmed) {
        const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
        await Http.patch(`${slug}/shop_manager/time_table/${this.reservation.id}/cancel`, {})
          .then((response) => {
            this.$emit('reload')
            this.$emit('close')
          })
          .catch((error) => {
            console.log(error)
          });
      }
    },
    posOrderDetail() {
      const items_detail = this.reservation.pos_order_data.items.map(item => `${item.name}　${item.quantity}　${item.price}円`).join("\n");
      const payment_dttm = this.reservation.pos_order_data.payment_dttm ? moment(this.reservation.pos_order_data.payment_dttm || '').format('YYYY/MM/DD HH:mm') : '';
      return `伝票番号：${this.reservation.pos_order_data.order_no}
        会計時刻：${payment_dttm}
        ------------------------------------------------
        ${items_detail}
        ------------------------------------------------
        会計 ${this.reservation.pos_order_data.sum} 円
      `
    },
    onOpenMergeModal(reservation) {
      this.$emit('close')
      this.$emit('openMergeModal', reservation)
    },
    changeStayTime($event) {
      if (this.course_id !== null) {
        this.getCourse(this.course_id)
        const course = this.courses.filter(function (val) {
          return (val.id == $event);
        })
        this.stay_minutes = course[0].stay_time
      }
    },
    changeShowCourseModal() {
      if (this.showCourseModal) {
        this.showCourseModal = false;
      }
      else {
        this.showCourseModal = true;
        this.showStatus = true;
      }
    },
    showItemPosData($event, index) {
      document.getElementById(`accordion-${index}`).classList.toggle("active")
      const panel = document.getElementById(`panel-${index}`);
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
    formartPaymentDT(date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : '';
    },
    async getCourses(date) {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/course/get_courses`;

      await Http.get(path, {
        date: date
      })
      .then((response) => {
        this.courses = response.data.courses;
        this.$emit('loading', false)
      })
      .catch((error) => {
        console.log(error);
        this.$emit('loading', false)
      });
    },
    async getCourse(course_id) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/course/show_course/${course_id}`;

      await Http.get(path, {
      })
      .then((response) => {
        this.course = response.data.course;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('time', this.start_time, '時分')
      this.errors = validator.errors;
    },

    submitSuccess() {
      this.$emit('reload');
      this.$emit('close');
    }
  },
}
</script>

<style lang="scss" scoped>
  .modal {
    background-color: #2125298a;
  }

  .modal-mb {
    margin-bottom: calc(100vh - 98%);
  }
  .pd-0 {
    padding: 0 0 20px 0 !important;
  }
  .btn-outline-primary {
    color: #2c2c2c;
    background-color: #D2E1C8;
    border: 1px solid #D2E1C8 !important;
  }
  .btn-unset {
    background: transparent;
    border: 2px solid #0090d7 !important;
    color: #0090d7;
  }
  .btn-outline-primary-seating {
    color: #2c2c2c;
    background-color: #D2E1C8;
    border: 1px solid #D2E1C8 !important;
  }
  .btn-outline-primary-seating:hover{
    opacity: 0.8;
  }
  .btn-outline-primary-finished {
    color: #2c2c2c;
    background-color: #999999;
    border: 1px solid #999999 !important;
  }
  .btn-outline-primary-finished:hover{
    opacity: 0.8;
  }
  .modal-header, .modal-body {
    padding: 0.6rem 1rem;
  }
  .modal-footer {
    padding: 0rem 1rem;
  }
  .modal-dialog {
    z-index: 1050;
    max-width: 630px;
  }
  .mx-datepicker {
    width: 100%;
  }

  #reservation-types option:disabled {
    color: #999;
  }

  .display-none {
    display: none !important;
  }
  .disable {
    background: #cccccc;
    cursor: not-allowed;
    pointer-events: none;
  }
  .customer_table {
    width: 100%;
    font-size: 15px;
    th {
      width: 33%;
      background-color: #0090d7;
      color: #fff;
      font-weight: unset;
      padding: 10px 0px 10px 60px;
    };
    td {
      padding: 5px 0px 5px 60px;
      border-bottom: 1px solid #DDDDDD;
    }
  }
  .customer-details {
    padding-top:10px;
    font-size: 16px;
    display: flex;
    .label {
      font-weight: 600;
    }
    .count-reservation {
      margin-left: 10px;
    }
  }
  .reserved {
    border: 2px solid #0090d7;
    color: #0090d7;
    background: #fff;
  }
  .seating {
    border: 2px solid #0090d7;
    background: #0090d7;
    color: #fff;
  }
  .finished {
    border: 2px solid #767676;
    background: #767676;
    color: #fff;
  }
  .not_show {
    border: 2px solid #B96E71;
    background: #fff;
    color: #B96E71;
  }
  .cancelled {
    border: 2px solid #B96E71;
    background: #B96E71;
    color: #fff;
  }
  .button_state {
    width: 22%;
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    border-radius: 5px;
    font-weight: bold;
    &:hover {
      opacity: 0.7;
    }
  }
  .text_state {
    text-align: center;
    width: 100%;
  }
  .modal-header {
    align-items: center;
  }
  .image_set {
    object-fit: cover;
  }
  .box_list {
    height: 100px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .list_status {
    width: 100%;
    padding-left: 20px;
  }
  .customer-details {
    background: #fff1e8;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .customer-right-details {
    text-align: right;
    color: blue;
    font-size: 16px;
  }
  .column-1 {
    padding-left: 40px;
    width: 160px;
  }
  .column-2 {
    width: 110px;
  }
  .column-3 {
    width: 155px;
    margin-left: 10px;
  }
  .column-4 {
    margin-left: 10px;
    width: 135px;
  }
  .d-flex-content {
    display: flex;
  }
  .reservation_null {
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 480px) {
    .count-reservation {
      margin-left: 0 !important;
    }
    .modal-mb {
      margin-bottom: calc(100vh - 150%);
    }
    .d-block-content {
      display: block;
    }

    .column-1 {
      padding-left: 30px;
      width: 110px;
    }
    .column-2 {
      width: 60px;
    }
    .column-3 {
      width: 85px;
      margin-left: 20px;
    }
    .column-4 {
      margin-left: 20px;
      width: 70px;
    }
  }

  .height-29 {
    height: 21px;
  }
  .unhover:hover {
    opacity: unset !important;
  }
  .accordion {
    cursor: pointer;
    padding: 15px 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    background: #fff;
    padding-bottom: 0;
  &.active {
    border-bottom: none;
  }
}


/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: #ffefe5;
  display: none;
  padding-right: 0;
  overflow: hidden;
  font-size: 12px;
}

.info_adjust-item {
  padding: 18px 18px;
  background-color: #ffefe5;
  padding-right: 18px;
  overflow: hidden;
}

button.accordion .icon-acc {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
  margin-top: 8px;
}

button.accordion.active .icon-acc {
  position: relative;
  margin-top: 17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
}

.header-res {
  display: flex;
  align-items: stretch;
  .col-res-1 {
    width: 30%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-2 {
    width: 23%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-3 {
    width: 23%;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

.border-bottom-res {
  width: 100%;
  border-bottom: 1px solid #DEE2E6;
  margin-top: 15px;
}
.w-30-res {
  width: 30.5% !important;
}

.w-27-res {
  width: 27% !important;
}

.mr-0 {
  margin-right: 0 !important;
}


.item-container {
  border-top: 1px solid #0090d7;
  border-bottom: 1px solid #0090d7;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.single_line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.first_span {
  flex: 1 1 auto;
}
.second_span {
  flex: 0 0 auto;
}
.form_select_table {
  --vs-font-size: 0;
  --vs-actions-padding: 8px 0px 8px 0px;
  --vs-controls-size: 0;
}
.form-modal-reservation {
  --vs-border-color: #DDDDDD;
}
</style>
