export default class ErrorModalService {
    constructor(errors) {
        this.errors = errors;
        this.blankError = this.errors === '' || this.errors === null;
        this.isMediaError = this.errors.media && this.errors.media===true;
        this.errorMessage = null
        if(this.errors.media){
                 this.errorMessage = Object.fromEntries(
                    Object.entries(this.errors)
                        .map(([key, value]) => (key !== 'media' ? [key, value] : null))
                        .filter(entry => entry !== null)
                );
        }
        else{
            this.errorMessage = this.errors
        }
    }
    getAlertType(){
        //In case of changing a reservation via Tabelog
        // Including change of number of persons, date and time
        const alert1={
            title: "グルメサイトからの予約を変更しますか？",
            text: `グルメサイトの予約はキャンセルされ、新たに「電話予約」として受付します。

                    下記をお客様に了承ください。
                    ・グルメサイトからお客様にキャンセル通知が送信されます。
                    ・グルメサイトの特典・ポイント利用、ネットでの変更/キャンセルできなくなります。`,
            cancelButton: "戻る",
            confirmButton: "予約を変更する",
            behaviour: null
        }
        //n case only seats are changed for reservations made via Tabelog.
        // Not including change of number of persons, date and time
        const alert2={
            title: "グルメサイトからの予約の席を変更しますか？",
            text: `この変更はグルメサイトには反映されません。
                   グルメサイト管理画⾯にて手動で席の変更をお願いいたします。
                   ※同じテーブグループへの席移動は問題ありません。`,
            cancelButton: "戻る",
            confirmButton: "席を変更する",
            behaviour: null
        }
        //In case of making Cancellation of a reservation via Tabelog.
        // *NoShow is not applicable.
        // *Only reservation through Gourmet Log, excluding HPG.
        const alert3={
            title: "グルメサイトからの予約をキャンセルしますか？",
            text: `グルメサイトから顧客にキャンセル通知が送信されます。`,
            cancelButton: "戻る",
            confirmButton: "予約をキャンセルする",
            behaviour: null
        }
        //When you try to move a reservation from a telephone reservation/WI, or any other reservation route to a table or date/time not associated with the Gourmet Website.
        // *Reservation channels other than Tabelog
        const alert4={
            title: "グルメサイトと紐づけがない席または日時に変更しようとしています",
            text: `・お客様がネットで予約変更/キャンセルした時に反映されなくなります。
                    ※グルメサイト側で変更内容のご確認をお願いします。`,
            cancelButton: "戻る",
            confirmButton: "予約を変更する",
            behaviour: null
        }

        switch (this.errorMessage.errors){
            case 'TABLE_NOT_LINKED':
                return alert4
            case 'TIME_NOT_AVAILABLE':
                return  alert4
            case 'NO_CHANGES':
                return  alert4
            case 'EMAIL_CANCEL_WARNING':
                return alert1
            case 'CANCEL_EMAIL_CANCEL_WARNING':
                return alert3
            case 'EMAIL_TABLE_CHANGE_WARNING':
                return alert2
            case 'TABLE_CHANGE_WARNING':
                return alert4
            default:
                return alert4
        }
    }

}