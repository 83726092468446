<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            予約ブロック登録
            <br />
            <span style="font-size: 12px;">オンラインの予約をブロックできます。ウォークイン・予約登録（電話受付など）の予約登録は可能です。</span>
          </h5>
          <button type="button" class="close close_reservation_modal close-block" @click="onClose"><span>&times;</span></button>
        </div>
        <div class="modal-body pd-0" id="modal-body">
          <div class="form-modal-reservation">
            <div class="row mt_small">
              <div class="col-md-5">
                <label>テーブル<span class="require">テーブル</span></label>
                <v-select v-model="shop_table_ids"
                  :options="shop_tables"
                  :reduce="o => o.id"
                  label="full_name"
                  :clearable="false" :searchable="false"
                  :multiple="true"
                  class="form_select_table"
                >
                  <template slot="selected-option" slot-scope="option">
                    <span class="single_line first_span">
                      {{ option.name }}
                    </span>
                    <span class="second_span">[{{option.number_of_people_min}}-{{option.number_of_people_max}}人]</span>
                  </template>
                </v-select>
                <small v-for="error in errors['shop_table_ids']" :key="error" class="error">{{ error }}</small>
              </div>
              <div class="col-md-5">
                <label>日付<span class="require">必須</span></label>
                <DatePicker
                  input-class="form-control cursor-pointer"
                  v-model="start_date"
                  value-type="format"
                  format="YYYY-MM-DD"
                  :clearable="false">
                  <i slot="icon-calendar"></i>
                </DatePicker>
                <small v-for="error in errors['start_date']" :key="error" class="error">{{ error }}</small>
              </div>
            </div>
            <div class="row mt_small">
              <div class="col-md-5">
                <label>開始時間<span class="require">必須</span></label>
                <div v-for="(time, index) in time_blocks" v-bind:key="index" v-show="!time._destroy" class="mb-10px">
                  <DatePicker
                    input-class="form-control cursor-pointer"
                    v-model="time.start_time"
                    value-type="format"
                    format="HH:mm"
                    type="time"
                    :show-second="false"
                    :minute-step="15"
                    :clearable="false">
                    <i slot="icon-calendar"></i>
                  </DatePicker>
                  <small v-for="error in errors[`start_time_${index}`]" :key="error" class="error">{{ error.replace(`Start time ${index}`, '開始時間') }}</small>
                </div>
              </div>
              <div class="col-md-6">
                <label>終了時間<span class="require">必須</span></label>
                <div v-for="(time, index) in time_blocks" v-bind:key="index" v-show="!time._destroy" class="mb-10px">
                  <div class="row">
                    <div  class="col-md-10">
                      <DatePicker
                        input-class="form-control cursor-pointer"
                        v-model="time.end_time"
                        value-type="format"
                        format="HH:mm"
                        type="time"
                        :show-second="false"
                        :minute-step="15"
                        :clearable="false">
                        <i slot="icon-calendar"></i>
                      </DatePicker>
                      <small v-for="error in errors[`end_time_${index}`]" :key="error" class="error">{{ error.replace(`End time ${index}`, '終了時間') }}</small>
                    </div>
                    <div class="col-md-2">
                      <button
                        type="button" class="btn btn-danger btn-round remove-item" @click="removeItemTime(index)">
                        <i class="fa fa-minus-square-o" aria-hidden="true"></i>
                        削除
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt_small block-addItem" v-if="!reservationId">
              <button
                v-if="time_blocks.filter((item) => {return (item._destroy == false)}).length < 5"
                type="button" class="btn btn-warning btn-round" 
                id="add-row"  @click="addItemTime">
                <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                時間帶追加
              </button>
            </div>
          </div>
        </div>
        <p class="note-block">※削除・変更は、左メニューの［予約ブロック］から操作します</p>

        <div class="modal-footer" style="border-top: 0;">
          <button type="button" class="btn btn-primary mr_small" @click="onSave">登録する</button>
          <button type="button" class="btn btn-outline-primary btn-unset" @click="onClose">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {decode_data} from "../../shared/util";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Http from "../../shared/http"

export default {
  components: {DatePicker, vSelect},
  props: {
    reservation: {
      type: Object,
    },
    datetime_blocks: {
      type: Array,
    },
    workingTime: {
      type: Object,
    }
  },
  data() {
    return {
      reservationId: null,
      start_date: moment(new Date()).format('YYYY-MM-DD'),
      start_time: '',
      errors: {},
      shop_tables: decode_data("#data-shop-tables"),
      end_time: '',
      shop_table_ids: [],
      time_blocks: [{
        id: null,
        start_time: null,
        end_time: null,
        _destroy: false
      }],
      reservations: []
    };
  },
  watch: {
    reservation: {
      handler(r) {
        this.errors = {}
        this.reservationId = r.id
        if (this.datetime_blocks.length > 0) {
          this.time_blocks = this.datetime_blocks
        } else {
          this.time_blocks = [{
            index: 1,
            start_time: moment(r.start_time).format('HH:mm'),
            end_time: moment(r.end_time).format('HH:mm'),
            _destroy: false
          }]
        }

        this.shop_table_ids = r.shop_table_ids
        this.start_date = moment(r.start_date).format('YYYY-MM-DD')
      },
      deep: true
    },
  },
  methods: {
    scrollTopScreen() {
      const elmnt = document.getElementById('modal-body');
      elmnt.scrollTo(0, 0);
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    onClose() {
      this.$emit('close');
    },
    async onSave() {
      this.$emit('loading', true);
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      this.time_blocks = this.time_blocks.map((v, index) => ({...v, index: index}))
      let time_line = []

      this.time_blocks.forEach(element => {
        const convertStart = moment(`${this.start_date} ${element.start_time}`);
        const convertEnd = moment(`${this.start_date} ${element.end_time}`);
        let start_time = element.start_time;
        let end_time = element.end_time;
        if (element.start_time && moment(this.workingTime.start_time) > convertStart) {
          const hour = convertStart.hour()
          const minute = convertStart.format("mm")
          start_time = `${24 + hour}:${minute}`
        }

        if (element.end_time && moment(this.workingTime.start_time) > convertEnd) {
          const hour = convertEnd.hour()
          const minute = convertEnd.format("mm")
          end_time = `${24 + hour}:${minute}`
        }

        time_line.push({
          id: element.id,
          index: element.index,
          start_time: start_time,
          end_time: end_time,
          _destroy: element._destroy
        })
      });

      time_line.sort((a, b) => a.start_time?.localeCompare(b.start_time));

      await Http.post(`${slug}/shop_manager/reservation_block`, { reservation: {
          start_date: this.start_date,
          shop_table_ids: this.shop_table_ids,
          time_blocks: time_line,
          number_of_people: 1,
          reservation_type: 'admin',
          last_name: 'admin',
          state: 30,
        },
      })
        .then((response) => {
          this.$emit('reload')
          this.$emit('close')
        })
        .catch((error) => {
          this.$emit('loading', false);
          console.log(error)
          this.errors = error.response.data
        });
    },
    addItemTime() {
      this.time_blocks.push({
        id: null,
        start_time: null,
        end_time: null,
        _destroy: false
      })
    },
    removeItemTime(index) {
      this.time_blocks[index]._destroy = true;
      this.time_blocks.push()
    }
  },
}
</script>

<style lang="scss" scoped>
  .modal {
    background-color: #2125298a;
  }

  .modal-mb {
    margin-bottom: calc(100vh - 98%);
  }
  .pd-0 {
    padding: 0 0 20px 0 !important;
  }
  .btn-outline-primary {
    color: #2c2c2c;
    background-color: #D2E1C8;
    border: 1px solid #D2E1C8 !important;
  }
  .btn-unset {
    background: transparent;
    border: 2px solid #0090d7 !important;
    color: #0090d7;
  }
  .btn-outline-primary-seating {
    color: #2c2c2c;
    background-color: #D2E1C8;
    border: 1px solid #D2E1C8 !important;
  }
  .btn-outline-primary-seating:hover{
    opacity: 0.8;
  }
  .btn-outline-primary-finished {
    color: #2c2c2c;
    background-color: #999999;
    border: 1px solid #999999 !important;
  }
  .btn-outline-primary-finished:hover{
    opacity: 0.8;
  }
  .modal-header, .modal-body {
    padding: 0.6rem 1rem;
  }
  .modal-footer {
    padding: 0rem 1rem;
  }
  .modal-dialog {
    z-index: 1050;
    max-width: 630px;
  }
  .mx-datepicker {
    width: 100%;
  }

  #reservation-types option:disabled {
    color: #999;
  }

  .display-none {
    display: none !important;
  }
  .disable {
    background: #cccccc;
    cursor: not-allowed;
    pointer-events: none;
  }
  .text_state {
    text-align: center;
    width: 100%;
  }
  .modal-header {
    align-items: center;
  }
  .image_set {
    object-fit: cover;
  }
  .box_list {
    height: 100px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .list_status {
    width: 100%;
    padding-left: 20px;
  }
  .customer-details {
    background: #fff1e8;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .customer-right-details {
    text-align: right;
    color: blue;
    font-size: 16px;
  }
  .column-1 {
    padding-left: 40px;
    width: 160px;
  }
  .column-2 {
    width: 110px;
  }
  .column-3 {
    width: 155px;
    margin-left: 10px;
  }
  .column-4 {
    margin-left: 10px;
    width: 135px;
  }
  .d-flex-content {
    display: flex;
  }
  .reservation_null {
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 480px) {
    .count-reservation {
      margin-left: 0 !important;
    }
    .modal-mb {
      margin-bottom: calc(100vh - 150%);
    }
    .d-block-content {
      display: block;
    }

    .column-1 {
      padding-left: 30px;
      width: 110px;
    }
    .column-2 {
      width: 60px;
    }
    .column-3 {
      width: 85px;
      margin-left: 20px;
    }
    .column-4 {
      margin-left: 20px;
      width: 70px;
    }
  }

  .height-29 {
    height: 21px;
  }
  .unhover:hover {
    opacity: unset !important;
  }
  .accordion {
    cursor: pointer;
    padding: 15px 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    background: #fff;
    padding-bottom: 0;
  &.active {
    border-bottom: none;
  }
}


/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: #ffefe5;
  display: none;
  padding-right: 0;
  overflow: hidden;
  font-size: 12px;
}

.info_adjust-item {
  padding: 18px 18px;
  background-color: #ffefe5;
  padding-right: 18px;
  overflow: hidden;
}

button.accordion .icon-acc {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
  margin-top: 8px;
}

button.accordion.active .icon-acc {
  position: relative;
  margin-top: 17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
}

.header-res {
  display: flex;
  align-items: stretch;
  .col-res-1 {
    width: 30%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-2 {
    width: 23%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-3 {
    width: 23%;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

.border-bottom-res {
  width: 100%;
  border-bottom: 1px solid #DEE2E6;
  margin-top: 15px;
}
.w-30-res {
  width: 30.5% !important;
}

.w-27-res {
  width: 27% !important;
}

.mr-0 {
  margin-right: 0 !important;
}


.item-container {
  border-top: 1px solid #0090d7;
  border-bottom: 1px solid #0090d7;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.single_line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.first_span {
  flex: 1 1 auto;
}
.second_span {
  flex: 0 0 auto;
}
.form_select_table {
  --vs-font-size: 0;
  --vs-actions-padding: 8px 0px 8px 0px;
  --vs-controls-size: 0;
}
.form-modal-reservation {
  --vs-border-color: #DDDDDD;
}

.mb-10px {
  margin-bottom: 10px;
}

.remove-item {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  margin-top: 0;
  padding: 8px 15px;
  left: 0;
}

.block-addItem {
  text-align: center;
  display: block !important;
}

.note-block {
  width: 100%;
  border-top: 1px solid #dee2e6;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-left: 0.6rem;
  font-size: 12px;
}

.close-block {
  padding-top: 0 !important;
  margin-top: -1.9rem !important;
}
</style>
