const OWNERS_STATUS = [
  {value: 'active', label: 'サービス利用中'},
  {value: 'admin_cancel', label: '利用停止中'},
  {value: 'inactive', label: '解約済み'},
  {value: 'not_set_pasword', label: 'パスワード未設定'},
  {value: 'owner_cancel', label: '解約申込中'},
];
const RESTAURANT_STATUS = [
  {value: 'active', label: 'サービス利用中'},
  {value: 'admin_cancel', label: '利用停止中'},
  {value: 'inactive', label: '解約済み'},
  {value: 'owner_cancel', label: '解約申込中'},
  {value: 'cancel', label: '解約申請'},
  {value: 'reactive', label: '解約申請の取消'},
];
export default {
  OWNERS_STATUS,
  RESTAURANT_STATUS
};