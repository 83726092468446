import moment from 'moment';
import constant from './constant';

export default {
  compact: (array) => {
    return array.filter(e => e != null);
  },
  removeDuplicate: (array) => {
    return array.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  },
  showCurrency: (number) => {
    const formatter = new Intl.NumberFormat('ja-JP', {style: 'currency', currency: 'JPY'});
    return formatter.format(number);
  },
  sleep: (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  },
  getUrlParameter: (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  formatDate(date) {
    if (date) {
      return moment(date).format('YYYY/MM/DD');
    } else {
      return '';
    }
  },
  showZipcode: (code) => {
    return String(code).substr(0, 3) + '-' + String(code).substr(3);
  },
  showPrice: (price) => {
    return new Intl.NumberFormat('ja-JP').format(price);
  },
  formatTel(tel) {
    if (tel.length == 10) {
      return tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else if (tel.length == 11) {
      return tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  },
  getPrefectureName(prefectures, id) {
    const prefecture = prefectures.find(e => e.code === id);
    return prefecture ? prefecture.name : '';
  },
  getTagName(tags, id) {
    const tag = tags.find(e => e.id === id);
    return tag ? tag.name : '';
  },
  formatDateWithoutLeadingZeros(date) {
    return moment(date, 'YYYY/MM/DD').format('YYYY/M/D');
  },

  formatDateZeros(date) {
    return moment(date, 'YYYY/MM/DD').format('YYYY/MM/DD');
  },

  formatDateJapan(date) {
    if (date) {
      return moment(date).format('YYYY年MM月');
    } else {
      return '';
    }
  },

  //status
  getOwnerStatus(status) {
    if (status) {
      const c_status = constant.OWNERS_STATUS.filter(owner_status => (owner_status.value === status));
      return c_status[0].label;
    } else {
      return '';
    }
  },

  getRestaurantStatus(status) {
    if (status) {
      const c_status = constant.RESTAURANT_STATUS.filter(rest_status => (rest_status.value === status));
      return c_status[0].label;
    } else {
      return '';
    }
  },

  csvExport(filename, data){
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(data));
    element.setAttribute('download', `${filename}_${moment(new Date()).format('YYYYMMDD')}.csv`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  }
};

export const SafeTrim = (value) => {
  return (typeof value === 'string') ? value.trim() : value;
};

export const CorrectNumber = (value) => {
  return (typeof value === 'string') ? value.trim().split('-').join('') : value;
};

export const AddressInfo = (postcode, prefectures) => {
  return new Promise(resolve => {
    const postalCode = require('japan-postal-code');
    postalCode.get(postcode, (address) => {
      const prefecture = prefectures.find(
        (p) => p.name === address.prefecture
      );
      if (prefecture) {
        resolve({
          code: prefecture.code,
          city: address.city,
          address: `${address.area}${address.street}`,
        });
      }
    });
  });
};

export const PopupDisplayError = (e) => {
  const elmnt = document.getElementById('body_wrapper_owner');
  e.fire({
    title: 'エラー',
    html: `<p>未入力の項目または入力内容に誤りがあります。</p>
           <p>ご確認いただき、再度登録をお願いいたします。</p>`,
    confirmButtonText: '閉じる',
    showCloseButton: false,
    didClose: () => elmnt.scrollTo(0, 0),
    customClass: {
      confirmButton: 'confirm-popup-owner',
      title: 'title-popup-owner',
      htmlContainer: 'html-popup-owner',
    },
  });
};

export const DisableScrollBody = (e) => {
  const wrapper = document.getElementsByClassName("wrapper")[0];
  if(e) {
    document.body.classList.add("modal-open")
    if(wrapper != undefined) {
      wrapper.classList.add("modal-open")
    }
  } else {
    document.body.classList.remove("modal-open")
    if(wrapper != undefined) {
      wrapper.classList.remove("modal-open")
    }
  }
}

export const DisableScroll = () => {
  const wrapper = document.getElementsByClassName("wrapper")[0];
  if(wrapper != undefined) {
    wrapper.classList.add("modal-open")
  }
}

export const EnableScroll = () => {
  const wrapper = document.getElementsByClassName("wrapper")[0];
  if(wrapper != undefined) {
    wrapper.classList.remove("modal-open")
  }
}

export const isTouchable = () => {
  return navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}
