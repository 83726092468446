import axios from "axios";

export class Http {
  constructor( init ) {
    let service = axios.create({
      baseURL: window.location.origin,
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").getAttribute("content"),
        "Content-Type": "application/json",
      },
    });
    service.interceptors.response.use(init.handleSuccess, init.handleError);
    this.service = service;
  }

  defaultHandleSuccess(response) { return response; }

  defaultHandleError(error) { return error; }

  get(path, params = {}) {
    return this.service.request({
      method: "GET",
      url: path,
      responseType: "json",
      params: params,
    });
  }

  patch(path, payload) {
    return this.service.request({
      method: "PATCH",
      url: path,
      responseType: "json",
      data: payload
    });
  }

  post(path, bodyPayload = false) {
    return this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: bodyPayload
    });
  }

  put(path, bodyPayload = false) {
    return this.service.request({
      method: "PUT",
      url: path,
      responseType: "json",
      data: bodyPayload
    });
  }

  delete(path, bodyPayload = false) {
    return this.service.request({
      method: "DELETE",
      url: path,
      responseType: "json",
      data: bodyPayload
    });
  }
}

export default new Http({});
