<template>
  <div>
    <div class="top-navi">
      <div class="d-flex flex-row align-items-center">
        <div class="m-0" style="font-size: 20px; min-width: 150px;">
          <button class="btn btn-icon btn-outline-primary btn-outline-primary-unfocus m-0" @click="prevDay">
            <i class="fa fa-angle-left" style="font-size: 2rem; color: #0090d7;" aria-hidden="true"></i>
          </button>
          {{displayDate}}
          <button class="btn btn-icon btn-outline-primary btn-outline-primary-unfocus m-0" @click="nextDay">
            <i class="fa fa-angle-right" style="font-size: 2rem; color: #0090d7;" aria-hidden="true"></i>
          </button>
          <DatePicker
            :value="date"
            :clearable="false"
            @change="changeDate">
            <template v-slot:input>
              <button class="btn btn-icon btn-outline-primary btn-outline-primary-unfocus m-0">
                <i class="fa fa-calendar-o" style="font-size: 1.4rem; color: #0090d7;" aria-hidden="true"></i>
              </button>
            </template>
            <i slot="icon-calendar"></i>
          </DatePicker>
        </div>
        <button class="btn px-3 py-1 ml-2 h-38 btn-today" :class="[isToday ? 'btn-primary btn-primary-unfocus' : 'btn-outline-primary btn-outline-primary-unfocus']" @click="toToday">今日</button>
        <div class="ml-4 top_title">
          <div>営業時間</div>
        </div>
        <div class="ml-2 d-block text-center" style="font-size: 14px; line-height: 1.2; min-width: 80px;">
          <div v-if="displayWorkingTimes.length === 0" class="text-danger">休業日</div>
          <div v-else-if="displayWorkingTimes.length && displayWorkingTimes[0].isHoliday" class="text-danger">休業日</div>
          <div v-else v-for="(wt, i) in displayWorkingTimes" :key="i">
            {{wt.start}}-{{wt.end}}
          </div>
        </div>
        <div class="ml-4 text-center top_title" >予約件数 {{summary.group}}組{{summary.people}}人</div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center">
      <div class="ml-2 notice d-flex ml-auto align-items-center py-1">
        <button class="btn btn-outline-blue" style="padding: 6px 8px; position: relative; margin-right: 15px;"
          @click="showNotice = !showNotice"
        >
          <img class="my-bell" style="margin-right: 5px; width: 22px;" src="../../../../../assets/images/icon_timetable/bell.svg" alt="img">
          当日予約のお知らせ <span v-if="unreadNewTodayReservationNotices.length" class="barge-noti-reservations">{{unreadNewTodayReservationNotices.length}}</span>
        </button>
        <div class="popover fade show bs-popover-bottom" v-if="showNotice">
          <div class="arrow"></div>
          <ul class="popover-body list-group">
            <li class="list-group-item" v-for="n, i in notices.slice(0, noticeViewSize)" :key="i">
              <span class="badge badge-danger" v-if="!n.confirmed_at">未確認</span>
              <span class="badge badge-secondary" v-else>確認済</span>
              {{n.title}}
              <button class="btn btn-primary btn-sm m-0 confirm" v-if="!n.confirmed_at" @click="confirmNotice(n)">確認</button>
            </li>
            <div class="mt-1 mb-1 text-center" v-if="notices.length < 1">お知らせがありません。</div>
            <div class="text-center mt-1" v-if="noticeViewSize < notices.length">
              <button class="btn btn-primary btn-sm m-0" @click="viewMoreNotice">もっと見る</button>
            </div>
          </ul>
        </div>
        <div class="backdrop" v-if="showNotice" @click="showNotice = false"></div>
        <button class="btn btn-outline-green ml-2 d-flex" style="padding: 6px 8px; margin-right: 15px;"
          @click="showModalReservationCourse = true"
        >
          <img class="my-fork" style="margin-right: 5px; width: 22px;" src="../../../../../assets/images/icon_timetable/icon_fork.svg" alt="img">
          本日のコース予約
        </button>
        <button class="btn btn-outline-primary btn-outline-primary-unfocus ml-2" style="padding: 6px 8px; margin-right: 15px;"
          @click="modalReservationBlock"
        >
          <img style="padding-right: 5px; height: 18px; " src="../../../../../assets/images/block_brown.svg" alt="img">
          予約ブロック
        </button>
        <button class="btn btn-outline-primary btn-outline-primary-unfocus ml-2" style="padding: 6px 8px;"
          @click="showStatus = true"
        >
          <img style="padding-right: 5px;" src="../../../../../assets/images/icon_timetable/icon_info.svg" alt="img">
          タイムテーブルの表示について
        </button>
      </div>
    </div>
    <div style="margin-bottom: 20px;">
      <div class="unread-notice" v-for="n, i in unreadNotices" :key="i">
        <i class="fa fa-exclamation-circle" style="vertical-align: middle;"></i>
        <span style="padding-left: 10px">{{n.title}}</span>
        <button class="btn btn-primary-confirm btn-sm m-0 confirm" @click="confirmNotice(n)">確認</button>
      </div>
    </div>
    <div class="modal fade show" style="display: block;" v-if="showStatus">
      <div class="modal-dialog modal-dialog-box">
        <div class="modal-content">
          <div class="modal-header timetable_header">
            <h5 class="modal-title">タイムテーブルの表示</h5>
            <button type="button" class="close close_reservation_modal" @click="showStatus=false"><span style="font-size: 2.5rem">&times;</span></button>
          </div>
          <div class="modal-body status_body">
            <div class="d-flex timetable_span">
              <span>チップの表示</span>
            </div>
            <div class="d-flex timetable">
              <div class="label reserved">予約</div>
              <div>
                <span>•••予約ありのステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label seating">着席</div>
              <div>
                <span>•••着席済み（来店済み）のステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label finished">会計済</div>
              <div>
                <span>•••会計が終わっているステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label not_show">来店無</div>
              <div>
                <span>•••来店がなかった(NoShow)のステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label block " style="font-size: 10px">ブロック</div>
              <div>
                <span>•••オンラインの予約受付をブロックしている箇所　※ウォークイン/予約登録は可能</span>
              </div>
            </div>
            <hr>
            <div class="d-flex timetable_span">
              <span>アイコンの表示</span>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img class="my-bell" style="margin-right: 10px; width: 22px;" src="../../../../../assets/images/icon_timetable/bell.svg" alt="img">
              </div>
              <div>
                <span>•••当日受け付けた予約</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img class="my-fork" style="margin-right: 5px; width: 22px;" src="../../../../../assets/images/icon_timetable/icon_fork.svg" alt="img">
              </div>
              <div>
                <span>•••コース予約</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <span class="square-number" style="padding: 0 8px; margin-right: 5px;">2</span>
              </div>
              <div>
                <span>•••お客様の来店回数</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img style="margin-right: 5px; width: 15px;" src="../../../../../assets/images/icon_timetable/icon_link.svg" alt="img">
              </div>
              <div>
                <span>•••テーブルを連結している席</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img style="margin-right: 5px; width: 15px;" src="../../../../../assets/images/icon_timetable/icon_message.svg" alt="img">
              </div>
              <div>
                <span>•••予約に関する備考欄に記載有</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ReservationCourseModal v-if="showModalReservationCourse" :date="date" @close="showModalReservationCourse = false" />
    <ReservationBlockModal v-show="showModalReservationBlock" :working-time="workingTime" :reservation="reservation" :datetime_blocks="datetime_blocks" @reload="$emit('reload')" @close="showModalReservationBlock = false" />
  </div>
</template>

<script>
import moment from 'moment';
moment.locale("ja", {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import Http from "../../../shared/http";
import ReservationCourseModal from './ReservationCourseModal.vue';
import ReservationBlockModal from '../../components/ReservationBlockModal.vue';
import {DisableScrollBody} from "../../../shared/util";

export default {
  components: {DatePicker, ReservationCourseModal, ReservationBlockModal},
  props: {
    date: {
      type: Date,
    },
    summary: {
      type: Object,
    },
    workingTimes: {
      type: Array,
    },
  },
  data() {
    return {
      showStatus: false,
      showNotice: false,
      notices: [],
      noticeViewSize: 5,
      showModalReservationCourse: false,
      showModalReservationBlock: false,
      datetime_blocks: [],
      reservation: {},
      workingTime: {}
    };
  },
  watch: {
    showModalReservationCourse(newVal) {
      DisableScrollBody(newVal)

      return newVal;
    }
  },
  computed: {
    isToday() {
      return moment(this.date).isSame(moment(), 'day');
    },
    displayDate() {
      return moment(this.date).format('YYYY/MM/DD (ddd)')
    },
    displayWorkingTimes() {
      return this.workingTimes.map(wt => {
        return {
          start: moment(wt.start_time).format("HH:mm"),
          end: moment(wt.end_time).format("HH:mm"),
          isHoliday: wt.is_holiday,
        };
      })
    },
    unreadNotices() {
      return this.notices.filter(n => !n.confirmed_at)
    },
    unreadNewTodayReservationNotices() {
      return this.notices.filter(n => n.notice_type == 'new_today_reservation' && !n.confirmed_at)
    },
  },
  watch: {
    date: {
      handler(val) {
        this.notices = []
        this.noticeViewSize = 5
        this.getNotices(val);
      },
      immediate: true,
    },
    showModalReservationCourse(newVal) {
      DisableScrollBody(newVal)

      return newVal;
    },
    showStatus(newVal) {
      DisableScrollBody(newVal)

      return newVal;
    }
  },
  methods: {
    moment: function () {
      return moment();
    },
    toToday() {
      this.$emit('change-date', new Date());
      this.$root.$emit('check-reservation', new Date());
    },
    prevDay() {
      const timestamp = new Date(this.date.getTime() - 24 * 60 * 60 * 1000)
      this.$emit('change-date', timestamp);
      this.$root.$emit('check-reservation', timestamp);
    },
    nextDay() {
      const timestamp = new Date(this.date.getTime() + 24 * 60 * 60 * 1000)
      this.$emit('change-date', timestamp);
      this.$root.$emit('check-reservation', timestamp);
    },
    changeDate(date) {
      this.$emit('change-date', date);
      this.$root.$emit('check-reservation', date);
    },
    async getNotices(date) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.get(`${slug}/shop_manager/shop_notices`, {
        date: moment(date).format("YYYY-MM-DD"),
        page_size: 1000,
        page: 1,
      })
        .then((response) => {
          const data = response.data
          this.notices = this.notices.concat(data.notices)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewMoreNotice() {
      this.noticeViewSize += 5
    },
    async confirmNotice(notice) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.patch(`${slug}/shop_manager/shop_notices/` + notice.id + "/confirm")
        .then((response) => {
          this.notices = this.notices.map(n => {
            if (n.id === notice.id) {
              n.confirmed_at = true
            }
            return n
          })
        })
        .catch((error) => {
          console.log(error);
        });

      if (notice.data?.redirect_path) {
        location.href = `${slug}${notice.data.redirect_path}`
      }
    },
    async modalReservationBlock() {
      this.reservation = { start_date: this.date }
      this.datetime_blocks = []

      this.workingTime = {
        start_time: moment(this.workingTimes.at(0).start_time).format('YYYY-MM-DD HH:mm'),
        end_time: moment(this.workingTimes.at(-1).end_time).format('YYYY-MM-DD HH:mm'),
      }

      this.datetime_blocks.push({
        id: null,
        start_time: moment(this.workingTimes.at(0).start_time).format('HH:mm'),
        end_time: moment(this.workingTimes.at(-1).end_time).format('HH:mm'),
        _destroy: false
      })

      this.showModalReservationBlock = true;
    },
  },
};
</script>

<style lang="scss">
$primary-color: #0090d7;
@import 'vue2-datepicker/scss/index.scss';
.mx-datepicker {
  width: auto;
}
.mx-btn-text {
  height: inherit;
}
</style>
<style lang="scss" scoped>

.btn-outline-primary {
  background-color: #fff;
  border: 2px solid #0090d7 !important;
  color: #0090d7;
  font-size: 20px;
  font-weight: 550;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-green {
  color: #076c29;
  border: 2px solid #076c29;
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-blue {
  color: #005aff;
  border: 2px solid #005aff;
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.timetable {
  align-items: center;
  margin-bottom: 5px;
  .label {
    padding: 8px 0px;
    margin-right: 4px;
    min-width: 45px;
    width: 45px;
    font-size: 12px;
    text-align: center;
  }
}
.h-38 {
  height: 38px;
}

.supplementary {
  border-radius: 6px;
  border: 1px dashed black;
  font-size: 11px;
  line-height: 13px;
  background-color: #fff;
}

.square-number {
  background-color: #0090d7;
  min-width: 16px;
  color: #FFFFFF;
  display: inline-block;
  text-align: center;
}

.notice {
  position: relative;
  margin-bottom: 15px;
  > .btn {
    padding: 6px 12px;
    margin: 0;
    min-height: 38px;
    font-size: 13px;
    i.fa {
      font-size: 1.2rem;
    }
  }
  .popover {
    position: absolute;
    top: 39px;
    left: -250px;
    min-width: 600px;
    font-size: 12px;

    .arrow {
      left: 300px;
    }
    .list-group-item {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 0.5rem 0.5rem;
    }
  }
  .popover-body {
    overflow-y: auto;
    max-height: 400px;
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
  }
}

.btn.confirm {
  float: right;
}

.btn-primary-confirm {
  background-color: #ffffff;
  border: 1px solid #0090d7;
  color: #212529;
}
.btn-primary-confirm:hover {
  opacity: 0.7;
}
.modal-dialog {
  z-index: 1050;
  max-width: 560px;
}
.modal-dialog-box{
  max-width: 619px;
}
.btn-today {
  font-size: 16px !important;
}

.unread-notice {
  padding: 4px 12px;
  background: #DD5858;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  margin-top: 4px;
  line-height: 26px;
  i.fa {
    font-size: 1.2rem;
  }
}
.top_title {
  font-size: 20px;
  min-width: 88px;
}
.timetable_span {
  font-weight: 700;

}
.status_body {
  padding-left: 30px;
}
.timetable_header {
  padding-left: 30px;
  align-items: center !important;
  .modal-title {
    font-weight: 600;
    padding-top: 10px;
  }
}
.opacity-unset:hover {
  opacity: unset !important;
}
.annotate_ipad {
  display: none !important;
}

.barge-noti-reservations {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #DD5858;
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background-color: #2125298a;
}
.my-fork{
  background: #03af7a;
  padding: 2px;
  border-radius: 50%;
}
.my-bell{
  background: #005aff;
  padding: 2px;
  border-radius: 50%;
}
@media screen and (max-width: 1340px) {
  .annotate_ipad {
    display: flex !important;
  }
  .annotate {
    display: none !important;
  }
}
@media screen and (min-width: 996px) and (max-width: 1112px) {
  .supplementary {
    display: none !important;
  }
}
</style>
