
export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const decode_data = (selector) => {
  const data_str = document.querySelector(selector)?.value;
  if (!data_str) return null;
  // Ref https://www.petitmonte.com/javascript/base64.html
  return JSON.parse(decodeURIComponent(escape(atob(data_str))));
}

export const get_url_parameter = (name) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const DisableScrollBody = (e) => {
  const wrapper = document.getElementsByClassName("wrapper")[0];

  if(e) {
    document.body.classList.add("modal-open")
    if(wrapper != undefined) {
      wrapper.classList.add("modal-open")
    }
  } else {
    document.body.classList.remove("modal-open")
    if(wrapper != undefined) {
      wrapper.classList.remove("modal-open")
    }
  }
}
