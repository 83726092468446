<template>
  <div v-if="is_reservation_exist_outside_business_hour">
    <p style="color: red">※営業時間外の予約が存在します。 予約一覧より修正してください</p>
  </div>
</template>
<script>
import moment from 'moment';
import Http from "../../../shared/http"
export default {
  props: {
    date: {
      type: Date,
    }
  },
  data() {
    return {
      localDate: this.date,
      is_reservation_exist_outside_business_hour: false
    }
  },
  async mounted() {
    await this.checkIfReservationExistOutsideBusinessHour();

    this.$root.$on('check-reservation', date => {
      this.localDate = date;
      this.checkIfReservationExistOutsideBusinessHour();
    });
  },
  methods: {
    async checkIfReservationExistOutsideBusinessHour() {
      const date_param = moment(this.localDate).format("YYYY-MM-DD");
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      try {
        const response = await Http.get(`${slug}/shop_manager/time_table/is_reservation_outside_business_hour`, {
          type: "day",
          date: date_param,
        });
        this.is_reservation_exist_outside_business_hour = response.data.reservation_outside_business_hour_exist;
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>