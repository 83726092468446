import Vue from "vue/dist/vue.esm";
import {decode_data, get_url_parameter} from "../../shared/util";
import TopNavigation from "./components/TopNavigation.vue";
import TimeTable from "./components/TimeTable.vue";
import ReservationExistOutsideBusinessHourLabel from "./components/ReservationExistOutsideBusinessHourLabel.vue";
import moment from "moment";
import Http from "../../shared/http";
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
$(function () {
  if (document.getElementById("shop-manager-time-table")) {
    new Vue({
      el: "#shop-manager-time-table",
      components: { TopNavigation, TimeTable, ReservationExistOutsideBusinessHourLabel },
      data() {
        return {
          shop: decode_data("#data-shop"),
          date: new Date(get_url_parameter("date") || new Date()),
          timeTable: [],
          summary: {},
          workingTimes: [],
          loading: false,
        };
      },
      computed: {},
      mounted() {
        this.loadData(this.date);
      },
      methods: {
        reload(silent = false) {
          this.loadData(this.date, silent);
        },
        changeDate(date) {
          this.loadData(date);
        },
        async loadData(date, silent = false) {
          if (!silent) this.loading = true;
          const date_param = moment(date).format("YYYY-MM-DD");
          const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
  
          await Http.get(`${slug}/shop_manager/time_table/time_table_data`, {
            type: "day",
            date: date_param,
          })
            .then((response) => {
              const data = response.data[date_param];
              this.date = date;
              this.summary = data.summary;
              this.workingTimes = data.working_times;
              let timeTable = data.time_table
              timeTable = this.countTableMergeNo(timeTable)
              timeTable = this.setOverlapLevel(timeTable)
              timeTable = this.expandNearMergeTable(timeTable)
              this.timeTable = timeTable;
              this.loading = false;
  
              if ('URLSearchParams' in window) {
                const url = new URL(location.href);
                const todayDate = moment().format("YYYY-MM-DD")
                if (date_param === todayDate) {
                  url.searchParams.delete('date');
                } else {
                  url.searchParams.set('date', date_param);
                }
                history.replaceState(null, '', url);
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        },
        //
        // テーブル結合のNoを計算する
        //
        countTableMergeNo(timeTable) {
          const mergeIds = []
          timeTable.forEach((t, i) => {
            t.reservations.forEach((r, j) => {
              if (r.is_table_merged) {
                if (!mergeIds.includes(r.id)) mergeIds.push(r.id)
                r.mergeNo = mergeIds.indexOf(r.id) + 1
              }
            })
          })
          return timeTable
        },
        //
        // 予約が重なっている場合に、重なりレベルを計算する
        //
        setOverlapLevel(timeTable) {
          timeTable.forEach((t, i) => {
            const tableReservations = t.reservations
            let tableOverlapLevel = 0
  
            tableReservations.forEach((r, j) => {
              tableReservations.filter((r2, j2) => {
                const overlap = j2 > j && r2.start < r.end && r2.end > r.start
                if (overlap) {
                  this.findAllSameReservation(timeTable, r.id).forEach((r) => { r.overlap = true })
                  this.findAllSameReservation(timeTable, r2.id).forEach((r) => { r.overlap = true })
                  r.overlapLevel ||= 0
                  if (r2.state == 'busy' || r.state == 'busy') {
                    r.overlapLevel = 0
                  } else {
                    r2.overlapLevel = r.overlapLevel + 1
                  }

                  if (tableOverlapLevel < r2.overlapLevel) {
                    tableOverlapLevel = r2.overlapLevel
                  }
                }
                return overlap;
              })
  
              if (r.type === 'pos') {
                this.findAllSameReservation(timeTable, r.id).forEach((r3) => {
                  r3.mergeable_reservations ||= []
                  r3.mergeable_reservations = r3.mergeable_reservations.concat(tableReservations.filter((r2, j2) => {
                    return j2 != j && r2.start < r.end && r2.end > r.start && r2.type != 'pos' && r2.pos_order_id == null
                  }))
                })
              }
            })
            timeTable[i].table.overlapLevel = tableOverlapLevel
            timeTable[i].table.offsetY ||= i
            if (i + 1 < timeTable.length) {
              timeTable[i + 1].table.offsetY = timeTable[i].table.offsetY + 1 + tableOverlapLevel
            }
          })
          return timeTable
        },
        //
        // となりのテーブルと結合して予約された場合は、繋がっているような見せ方にする
        //
        expandNearMergeTable(timeTable) {
          timeTable.forEach((t, i) => {
            if (i < 1) return
  
            const prevRow = timeTable[i - 1].reservations
            const prevTable = timeTable[i - 1].table
            const thisRow = t.reservations
            const thisTable = t.table
            thisRow.forEach((r, j) => {
              const prevR = prevRow.find((r2) => r2.id === r.id)
              if (prevR) {
                prevR.hide = true
                r.expandLevel = (prevR.expandLevel || 0) + (prevTable.overlapLevel || 0) + 1
              }
            })
          })
          return timeTable
        },
        findAllSameReservation(timeTable, id) {
          const result = []
          timeTable.forEach((t, i) => {
            t.reservations.forEach((r, j) => {
              if (r.id === id && r.state == 'busy') {
                result.push(r)
              }
            })
          })
          return result
        },
      },
    });
  }
});
