<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">コース内容</h5>
          <button type="button" class="close close_reservation_modal" @click="onClose"><span>&times;</span></button>
        </div>
        <div class="modal-body">
          <div class="form-modal-reservation">
            <div class="row">
              <div class="col-md-12">
                <span>コース画像</span>
                <div class="d-flex py-2">
                  <div v-for="(image, index) in course.course_images" :key="index" class="file_upload_image col-md-4" style="text-align: center; min-height: 50px; height: auto; margin-right: 0;">
                    <img :src="image.image_path">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>コース名</span>
                <div class="ml-2 pb-2">{{course.name}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>コースのPR</span>
                <div class="ml-2 pb-2">{{course.pr}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>コースの内容</span>
                <div class="ml-2 pb-2">
                  <div class="text_break">{{item_content}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>料理の補足</span>
                <div class="ml-2 pb-2 text_break">{{course.item_remark}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span>滞在時間</span>
                <div class="ml-2 pb-2">{{course.stay_time}} 分</div>
              </div>
              <div class="col-md-6">
                <span>料金</span>
                <div class="ml-2 pb-2">{{course.price}} 円（税込）</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>コースの有効期間</span>
                <div class="ml-2 pb-2">
                  <div v-for="(course_period, index) in course.course_periods" :key="index">
                    <span v-if="course_period.publish_date == null">有効期間なし</span>
                    <span v-else>{{convertDate(course_period.publish_date)}} ～ {{convertDate(course_period.expire_date)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>予約可能曜日</span>
                <div class="ml-2 pb-2">
                  <div v-for="(course_day, index) in course.course_days" :key="index">
                    {{getDay(course_day.day)}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>予約可能時間</span>
                <div class="ml-2 pb-2">
                  <span v-if="course.reservable_form == null">常時可能</span>
                  <span v-else>{{course.reservable_form}} ～ {{course.reservable_to}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>予約可能人数</span>
                <div class="ml-2 pb-2">
                  <span v-if="course.number_customer_min == null">人数制限なし</span>
                  <span v-else>{{course.number_customer_min}} ～ {{course.number_customer_max}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>一日の予約上限</span>
                <div class="ml-2 pb-2">{{course.limit_reservation_per_day}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>期間中の予約上限</span>
                <div class="ml-2 pb-2">{{course.number_reservation_limit}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>カテゴリー</span>
                <div class="ml-2 pb-2">
                  <div v-for="name in course.category_name" :key="name">
                    {{name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>備考・注意点</span>
                <div class="ml-2 pb-2">{{course.description}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span>キャンセルポリシー</span>
                <div class="ml-2 pb-2">{{course.cancellation_policy}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-unset" @click="onClose">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { DisableScrollBody } from "../../shared/util";
export default {
  props: {
    course: {
      type: Object,
    },
  },
  data() {
    return {
      item_content: ''
    }
  },
  watch: {
    course: {
      immediate: true,
      handler(r) {
        this.item_content = '';
        this.convertCookingItems(r);
        return r
      }
    }
  },
  methods: {
    convertCookingItems(course) {
      let itemName = '';
      let itemDescription = '';
      course.course_items.forEach(item => {
        itemName = this.convertItemText(item.item_name);
        itemDescription = this.convertItemText(item.item_description);
        this.item_content += itemName + itemDescription + '\n';
      });
      this.item_content = this.item_content.trim();
      course.course_items.push();
    },
    convertItemText(itemText) {
      if (itemText !== null && itemText !== '') {
        return itemText + '\n';
      } else {
        return '';
      }
    },
    onClose() {
      this.$emit('close');
    },
    convertDate(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    getDay(day) {
      switch (day) {
        case 1:
          return '月曜日'
        case 2:
          return '火曜日'
        case 3:
          return '水曜日'
        case 4:
          return '月曜日'
        case 5:
          return '木曜日'
        case 6:
          return '土曜日'
        case 0:
          return '日曜日'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .w_25_pt {
    width: 25%;
  }
  .file_upload_image img {
    height: 120px;
    object-fit: contain;
  }
  .text_break {
    white-space: break-spaces;
  }
</style>
