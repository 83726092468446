<template>
  <div class="modal fade show" id="reservation-course" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">本日のコース料理一覧</h5>
          <button type="button" class="close" @click="onClose"><span>&times;</span></button>
        </div>
        <div class="modal-body" style="padding-right: 0; padding-left: 0">
          <p style="padding-left: 30px">本日予約されているコース料理の一覧を表示しております</p>
          <div class="table-responsive">
            <table class="table" id="reservation_course_list">
              <thead class="">
                <tr>
                  <th style="padding-left: 20px;">時間</th>
                  <th>人数</th>
                  <th>コース名</th>
                  <th>テーブル名</th>
                  <th>顧客名</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(history, index) in courseReservationHistories" :key="index">
                  <td>{{history.reservation_time}}</td>
                  <td>{{history.number_of_people}}</td>
                  <td>{{history.course_name}}</td>
                  <td>{{history.table_name}}</td>
                  <td>{{history.customer_name}}</td>
                </tr>
              </tbody>
            </table>
            <div v-if="courseReservationHistories.length == 0" class="text-center">表示するデータはありません。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Http from "../../../shared/http"
export default {
  props: {
    date: {
      type: Date,
    },
  },
  data() {
    return {
      courseReservationHistories: [],
    };
  },
  async mounted() {
    await this.getCourseReservation();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async getCourseReservation() {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/course/course_reservation`;

      await Http.get(path, {
        date: this.date
      })
      .then((response) => {
        this.courseReservationHistories = response.data.course_reservations;
        this.$emit('loading', false)
      })
      .catch((error) => {
        console.log(error);
        this.$emit('loading', false)
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    background-color: #2125298a;
  }
  #reservation-course {
    .modal-dialog {
      max-width: 1024px;
      z-index: 1051;

    }
  }
  #reservation_course_list {
    thead {
      background: #0090d7;
      color: #ffff;
    }
    tbody tr > td {
      &:nth-child(1) {
        width: 15%;
        padding-left: 20px;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 40%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 20%;
      }
    }
  }
</style>
